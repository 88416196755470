import PaginationFooter from "src/components/Shared/PaginationFooter";
import React, { useCallback, useEffect, useState } from "react";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import BillingWorkspaceEntry from "./BillingWorkspaceEntry";
import { H3 } from "../Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";

const BillingWorkspaceList = ({ setWorkspaceCount, ...props }) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [billingWorkSpaceList, setBillingWorkSpaceList] = useState([]);
  const [billingWorkspaceLoaded, setBillingWorkspaceLoaded] = useState(false);

  const getBillingWorkSpaceList = useCallback(async () => {
    try {
      setBillingWorkspaceLoaded(false);
      setBillingWorkSpaceList([]);
      setWorkspaceCount(0);
      const res = await apiRequest("get", `/plans/:plan_id/workspaces`, {
        queries: { limit: limit, offset: offset * limit },
        params: { plan_id: props?.planId },
      });

      if (res.data.status === 200) {
        setBillingWorkSpaceList(res.data.data || []);
        setWorkspaceCount(res?.data?.meta?.count);
      } else {
        setBillingWorkSpaceList([]);
      }
      setBillingWorkspaceLoaded(true);
    } catch (error) {}
  }, [limit, offset, props?.planId, setWorkspaceCount]);

  useEffect(() => {
    getBillingWorkSpaceList();
  }, [getBillingWorkSpaceList]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return (
    <div>
      <H3 className="!text-lg !font-semibold">Assigned Workspaces</H3>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className={classNames("hidden rounded-tl-lg py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6", !props?.isNocharge ? "" : "rounded-tr-lg")}>
              Workspace
            </th>
            {!props?.isNocharge && (
              <>
                <th
                  scope="col"
                  className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
                  Biling Status
                </th>
                <th
                  scope="col"
                  className="hidden rounded-tr-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Billing Manager
                </th>
              </>
            )}
          </TRHeader>
        }
        colSpan="5"
        loaded={billingWorkspaceLoaded}
        dataExists={billingWorkSpaceList?.length > 0}>
        {billingWorkSpaceList?.map((item, key) => (
          <BillingWorkspaceEntry
            key={key}
            data={item}
            isNocharge={props?.isNocharge}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="Workspaces record"
        limit={limit}
        offset={offset}
        count={billingWorkSpaceList?.length}
        onChange={handlePaginationChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(BillingWorkspaceList);
