import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getMenuData } from "src/actions/menu";
import { FeatureFlag } from "src/components/FeatureFlags/FeatureFlag";
import NavItem from "src/components/Navigation/NavItem";
import NavigationPreviewBox from "src/components/Preview/NavigationPreviewBox";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import Button from "src/components/Shared/Buttons/Button";
import Logo from "src/components/Shared/Icons/Logo";
import SlideOver from "src/components/Shared/SlideOver";
import SeoTitle from "src/components/Shared/Text/SeoTitle";
import UserEdit from "src/components/Users/Edit/UserEdit";
import UserProfile from "src/components/Users/UserProfile";
import DomoProvider from "src/context/DomoProvider";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const AdminLayout = ({ user, menus, site, getMenuData, children, workspaceDetails }) => {
  const [userModelIsOpen, setUserModelIsOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("user");

  useEffect(() => {
    if (userId) {
      setUserModelIsOpen(true);
    } else {
      setUserModelIsOpen(false);
    }
  }, [userId]);

  const handleUserModalClose = () => {
    setUserModelIsOpen(false);

    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("user");
    updatedParams.delete("userTabs");
    setSearchParams(updatedParams);
  };

  const userNavigation = [
    {
      name: "My account",
      navName: "Users",
      path: `${window.location.pathname}?user=${user?._id}`,
    },
    { name: "User portal", path: `${site?.site_domain}`, navName: "SiteDomain", icon: <ArrowTopRightOnSquareIcon className="h-5 w-5" /> },
    { name: "Sign out", path: "/" },
  ];

  const [currentNavigation, setCurrentNavigation] = useState("");
  const [hasImpersonatedUserId, setHasImpersonatedUserId] = useState(false);

  const ref = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.href);
    setSidebarOpenMobile(false);
  };

  useEffect(() => {
    let convertNavigation = [{ name: "Workspaces", href: "/workspaces" }];

    if (["owner", "admin", "editor"].includes(user?.type)) {
      convertNavigation.push(
        { name: "Users", href: "/users" },
        { name: "Admins", href: "/admins" },
        { name: "Pages", href: "/pages" },
        { name: "Groups", href: "/groups" },
        { name: "SSO", href: "/sso" },
        // { name: "Emailer", href: "/emailer" },
        { name: "Activity", href: "/activity" },
        { name: "Settings", href: "/settings" },
      );
    }

    let path = window.location.pathname;
    let navigationTab = convertNavigation.find((tab) => path.includes(tab.href));
    if (navigationTab?.name) updateNavigation(navigationTab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, user]);

  const removePreviewUser = () => {
    asyncLocalStorage.removeItem("impersonatedUserId");
    window.location.reload();
  };

  useEffect(() => {
    let impersonatedUserId = localStorage.getItem("impersonatedUserId");
    if (impersonatedUserId) {
      setHasImpersonatedUserId(true);
    } else {
      setHasImpersonatedUserId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId")]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        let params = {};
        await getMenuData(params, ac.signal);
        setLoadedMenu(true);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId"), user?.type]);

  useEffect(() => {
    if (loadedMenu && !user) {
      window.location.href = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <FeatureFlagsProvider>
        <DomoProvider>
          <SeoTitle title={site.name} />
          <div className="flex h-full w-full flex-col">
            {(user?.type === "admin" || (user?.type === "editor" && user?.workspaces?.length > 1)) && (
              <Transition.Root
                show={sidebarOpenMobile}
                as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-40 lgx:hidden"
                  onClose={setSidebarOpenMobile}>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-40 flex">
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="-translate-x-full"
                      enterTo="translate-x-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-x-0"
                      leaveTo="-translate-x-full">
                      <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-in-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <div className="absolute right-0 top-0 -mr-12 pt-2">
                            <Button
                              type="button"
                              version="default"
                              className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                              onClick={() => setSidebarOpenMobile(false)}>
                              <span className="sr-only">Close Side Bar</span>
                              <XMarkIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                        </Transition.Child>
                        <div className="bg-headerColor pl-4">
                          <Logo />
                        </div>
                        <div className="mt-5 h-0 flex-1 overflow-y-auto">
                          <nav className="space-y-1 px-2">
                            {menus.map((item) => (
                              <FeatureFlag
                                key={item?.name}
                                feature={item?.name}>
                                <NavItem
                                  item={item}
                                  activeItem={currentNavigation}
                                  onClick={updateNavigation}
                                />
                              </FeatureFlag>
                            ))}
                          </nav>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                    <div
                      className="w-14 flex-shrink-0"
                      aria-hidden="true">
                      {/* Dummy element to force leftNavColor to shrink to fit close icon */}
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            )}

            <div className={classNames("flex w-full transition-all duration-150", sidebarOpen ? "lgx:pl-0" : "")}>
              <div className="fixed top-0 z-30 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
                <Logo>
                  {user?.type === "admin" || (user?.type === "editor" && user?.workspaces?.length > 1) ? (
                    <>
                      <Button
                        type="button"
                        version="default"
                        className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lgx:!hidden"
                        onClick={() => setSidebarOpenMobile(true)}>
                        <span className="sr-only">Open left navigation</span>
                        <Bars3BottomLeftIcon
                          className="h-6 w-6 text-headerTextColor"
                          aria-hidden="true"
                        />
                      </Button>
                      <Button
                        type="button"
                        version="default"
                        className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lgx:!flex"
                        onClick={() => setSidebarOpen(!sidebarOpen)}>
                        <span className="sr-only">Open leftNavColor</span>
                        <Bars3BottomLeftIcon
                          className="h-6 w-6 text-headerTextColor"
                          aria-hidden="true"
                        />
                      </Button>
                    </>
                  ) : (
                    <div className="pl-6"></div>
                  )}
                </Logo>
                <div className="flex w-full items-center justify-end pr-6">
                  <div className="ml-4 flex items-center lg:ml-6">
                    {/* Profile dropdown */}
                    <Menu
                      as="div"
                      className="flex items-center">
                      <Menu.Button className="flex items-center justify-center">
                        <div className="w-22 m-0 hidden h-6 items-center text-sm font-medium text-headerTextColor sm:flex">{user?.name ? user?.name : null}</div>
                        <span className="sr-only">Open user menu</span>
                        <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={user || {}} />}</div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-7 top-12 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <AdminNavDesktop userNavigation={userNavigation} />
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex h-full w-full">
              {/* Static leftNav for desktop */}
              {(user?.type === "admin" || (user?.type === "editor" && user?.workspaces?.length > 1)) && (
                <div className={classNames("fixed top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lgx:!flex lgx:w-64 lgx:flex-col", sidebarOpen ? "left-0" : "-left-64")}>
                  <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-lg">
                    <div className="grid gap-y-1">
                      {menus.map((item) => (
                        <FeatureFlag
                          key={item?.name}
                          feature={item?.name}>
                          <NavItem
                            item={item}
                            activeItem={currentNavigation}
                            onClick={updateNavigation}
                          />
                        </FeatureFlag>
                      ))}
                    </div>
                    {/* <NavItem item={{ name: "page data", path: "/pagedata" }} /> */}
                    <NavigationPreviewBox
                      removePreviewUser={removePreviewUser}
                      user={user}
                      hasImpersonatedUserId={hasImpersonatedUserId}
                    />
                  </nav>
                </div>
              )}
              <div className={classNames("relative z-10 w-full pt-16 transition-all duration-150", (user?.type === "admin" || (user?.type === "editor" && user?.workspaces?.length > 1)) && sidebarOpen ? "lgx:pl-64" : "lgx:pl-0")}>
                {window.location.pathname.includes("companies") || window.location.pathname.includes("users2") ? (
                  <div className="h-full min-h-[calc(100vh-56px)] w-full max-w-[1600px] overflow-hidden bg-white px-3 py-4 md:px-8 md:py-6">{children}</div>
                ) : (
                  <div className="flex min-h-[calc(100vh-56px)] w-full max-w-[1600px] flex-col gap-y-0 px-2 py-4 md:px-6 md:py-8 xl:px-12 xl:py-10 2xl:pl-16 2xl:pt-12 3xl:pl-24 3xl:pt-20">{children}</div>
                )}
              </div>
            </div>
          </div>
          <SlideOver
            isOpen={userModelIsOpen}
            handleClose={handleUserModalClose}
            title="Edit user"
            description="View, update, and modify user details and information below."
            maxWidth="xl:max-w-[1300px] lg:max-w-[1100px]">
            <UserEdit />
          </SlideOver>
        </DomoProvider>
      </FeatureFlagsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getMenuData })(AdminLayout);
