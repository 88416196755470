import PaginationFooter from "src/components/Shared/PaginationFooter";
import React, { useCallback, useEffect, useState } from "react";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import TransactionEntry from "./TransactionEntry";
import DateSearch from "src/components/Activity/DateSearch";
import TableOptions from "src/components/Shared/TableOptions";

const Transaction = ({ workspaceId, ...props }) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [transactions, setTransactions] = useState([]);
  const [meta, setMeta] = useState({});
  const [transactionLoaded, setTransactionsLoaded] = useState(false);

  const getTransactionList = useCallback(async () => {
    try {
      setTransactionsLoaded(false);

      const res = await apiRequest("get", workspaceId ? `/workspaces/:workspace_id/billing/transactions` : props?.planId ? `/plans/:plan_id/transactions` : `/transactions`, {
        queries: { limit: limit, offset: offset, start_date: startDate, end_date: endDate },
        params: workspaceId ? { workspace_id: workspaceId } : { plan_id: props?.planId },
      });

      if (res.data.status === 200) {
        setTransactions(res.data.data || []);
        setMeta(res?.data?.meta || {});
      } else {
        setTransactions([]);
      }
      setTransactionsLoaded(true);
    } catch (error) {}
  }, [limit, offset, startDate, endDate, workspaceId, props?.planId]);

  useEffect(() => {
    getTransactionList();
  }, [getTransactionList]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            inline={true}
            setEndDate={setEndDate}
            setStateDate={setStartDate}
            placeholder={"Select dates"}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            {!workspaceId && !props?.planId && (
              <th
                scope="col"
                className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
                Plan Name
              </th>
            )}
            <th
              scope="col"
              className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
              Date
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Payment
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              {workspaceId ? "Subscription Plan" : "Workspace"}
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Status
            </th>
          </TRHeader>
        }
        colSpan="5"
        loaded={transactionLoaded}
        dataExists={transactions?.length > 0}>
        {transactions?.map((item, index) => (
          <TransactionEntry
            key={index}
            data={item}
            workspaceId={workspaceId}
            showPlanName={!workspaceId && !props?.planId}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="Transaction record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(Transaction);
