import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditUserForm from "src/components/Users/Edit/ViewProfile/EditUserForm";
import { objectToFormData } from "src/helpers";
import useFetch from "src/hooks/useFetch";
import useMounted from "src/hooks/useMounted";

const UserProfileDetailsEdit = ({ groups, setGroups, fetchUser, me, updateUser }) => {
  const [updatedUserFields, setUpdatedUserFields] = useState({});
  const [successButtonStart, setSuccessButtonStart] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialCustomFields, setInitialCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [clearState, setClearState] = useState(0);

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user");

  const isMounted = useMounted();

  const {
    response: { data: user },
    refreshData: refreshUser,
  } = useFetch(`/users/details`, {
    method: "POST",
    data: { id: userId || null },
  });

  useEffect(() => {
    if (userId) {
      refreshUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (user?.type === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user?.type]);

  const loadCustomFields = useCallback(async () => {
    try {
      const { data: resData } = await apiRequest("GET", `/users/custom-fields`);
      if (isMounted.current && resData?.status === 200) {
        setInitialCustomFields(resData.data || []);
        setCustomFields(resData.data || []);
      }
    } catch (err) {}
  }, [isMounted]);

  useEffect(() => {
    loadCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setSuccessButtonStart(true);

    const updateData = {
      ...user,
      type: isAdmin ? "admin" : user.type === "editor" ? "editor" : "user",
      ...updatedUserFields,
      custom_fields: JSON.stringify(customFields),
    };

    delete updateData?.user_permissions;

    const convertedPayload = objectToFormData(updateData);

    try {
      const message = await updateUser(convertedPayload, true);
      setUpdatedUserFields({ password: "", confirm_password: "" });
      await fetchUser(userId);
      toast.success(message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message || "An error occurred");
    } finally {
      setSuccessButtonStart(false);
    }
  };

  useEffect(() => {
    if (user?._id && customFields.length > 0) {
      const updatedFields = customFields.map((field) => {
        const matchedField = user.custom_fields?.find((item) => item.custom_field_id === field._id);
        return { ...field, value: matchedField?.value ?? field.default_value ?? "" };
      });
      setInitialCustomFields(updatedFields);
      setCustomFields(updatedFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.custom_fields?.length, customFields.length]);

  const handleSetUser = (key, value) => {
    setUpdatedUserFields((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <EditUserForm
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        user={user}
        setUser={handleSetUser}
        groups={groups}
        setGroups={setGroups}
        customFields={customFields}
        setCustomFields={setCustomFields}
        clearState={clearState}
        {...user}
        {...updatedUserFields}
      />
      {user?.type === "admin" && me?.type !== "admin" ? null : (
        <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
          <Button
            version="gray"
            onClick={() => {
              refreshUser();
              setUpdatedUserFields({});
              setCustomFields(initialCustomFields);
              setIsAdmin(user?.type === "admin");
              setClearState((state) => state + 1);
            }}>
            Undo changes
          </Button>
          <Button
            version="primary"
            type="button"
            onClick={onSubmit}
            disabled={successButtonStart}
            loading={successButtonStart}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.user,
});

export default connect(mapStateToProps, { getUserDetails, updateUser })(UserProfileDetailsEdit);
