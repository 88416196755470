import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";
import UserProfile from "src/components/Users/UserProfile";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { toast } from "react-toastify";
import { formatDate } from "src/helpers/formatDate";
import { getPortalWorkspacesFirstPage } from "src/actions/impersonate";
import { resetWorkspaceDetails } from "src/actions/page";
import TRBody from "src/components/Shared/Table/TRBody";

const UserEntry = ({
  user,
  clearUserDetails,
  manageUserStatus,
  getPages,
  setDeleteId,
  lastRow,
  me,
  site,
  getAuthorizedUser,
  allowMulti = false,
  disableCheckButton = false,
  checkedUsers = [],
  setCheckedUsers = () => {},
  refreshUsers = () => {},
  manageUserRole = () => {},
  workspaceDetails = {},
  removeUserAccess = () => {},
  onClick = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  const URLParams = useParams();
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];

  const [activeStatus, setActiveStatus] = useState(true);

  useEffect(() => {
    setActiveStatus(user.active_status);
  }, [user.active_status]);

  const insertTypes = {
    SSO_LOGIN: "SSO",
    MANUAL_INPUT: "Manually",
    BULK_UPLOAD: "Bulk",
    DOMO_DATASET: "Dataset",
  };

  const setPreviewUserId = async (id) => {
    try {
      const { link } = await getPortalWorkspacesFirstPage({ user_id: id });

      if (link) {
        props?.resetWorkspaceDetails();
        localStorage.setItem("impersonatedUserId", id);
        const ac = new AbortController();
        await getAuthorizedUser(ac.signal);
        navigate(link);
      }
    } catch (err) {
      // toast.error("Currently user doesn't have any page assigned.");
    }
  };

  const navigateUserMenu = (menuOption) => {
    // onClick();
    navigate(`?user=${user._id}&userTabs=${menuOption}`);
  };

  const handleStatus = async () => {
    try {
      const message = await manageUserStatus(user);
      toast.success(message);
      refreshUsers();
      setActiveStatus(!activeStatus);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const isWorkspaceRoute = window.location.href.includes("workspaces") && URLParams?.id;

  const quickMenuItems = [
    {
      name: "View Profile",
      hideWhen: (user?.default_user && !me?.default_user) || (user.type === "admin" && me?.type !== "admin") || !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]),
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("View+Profile");
      },
    },
    {
      name: "Workspaces",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Workspaces");
      },
    },
    {
      name: "Permissions",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Editors");
      },
    },
    {
      name: "Data Access",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Data+Access");
      },
    },
    {
      name: "Activity",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Activity");
      },
    },
    {
      name: activeStatus ? "Deactivate" : "Activate",
      color: activeStatus ? "text-red-500" : "text-green-500",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        handleStatus();
      },
    },
    {
      hideWhen: user.default_user,
      type: "hr",
    },
    {
      name: `Impersonate`,
      hideWhen: (user?.default_user && !me?.default_user) || user.type === "admin" || me?.type !== "admin",
      isBeta: true,
      onClick: () => {
        setPreviewUserId(user._id);
      },
    },
    {
      name: "Remove Access",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin" || !isWorkspaceRoute || !authorizeUserComponentAccess(me, workspaceDetails?._id, "permission", ["update"]),
      isBeta: false,
      onClick: () => removeUserAccess(user._id),
    },
    {
      name: "Delete",
      hideWhen:
        (user?.default_user && !me?.default_user) ||
        user.default_user ||
        (user?.type === "admin" && workspaceDetails?._id) ||
        site.api_state === "DOMO_DATASET" ||
        !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) ||
        (me?.type !== "admin" && user?.type === "admin") ||
        me?._id === user?._id ||
        site?.api_state === "DOMO_DATASET",
      onClick: () => {
        setDeleteId(user._id);
      },
      color: "text-red-500",
    },
  ];

  const hasNoHiddenItems = quickMenuItems.filter((item) => !item.hideWhen && item.type !== "hr");

  const handleOnClick = () => {
    if (authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) && hasNoHiddenItems.length > 0) {
      // onClick(user._id);
      // navigate(user?.type === "admin" ? `/admins?user=${user._id}&pathname=${router.pathname}${router.search}` : `/users?user=${user._id}&pathname=${router.pathname}${router.search}`);
      navigate(user?.type === "admin" ? `?user=${user._id}` : `?user=${user._id}`);
    }
  };

  return (
    <TableRow
      keyId={user?._id}
      lastRow={lastRow}
      classes={hasNoHiddenItems.length > 0 ? "" : "!opacity-50"}
      isHoverable={true}>
      {allowMulti && me?.type === "admin" && (
        <TRBody className=" whitespace-nowrap !py-3 !pl-1 text-sm sm:!pl-4">
          <Checkbox
            isChecked={checkedUsers.includes(user._id)}
            onChange={() => {
              if (disableCheckButton) return;
              setCheckedUsers((cur) => {
                if (cur.includes(user._id)) {
                  return cur.filter((item) => item !== user._id);
                } else {
                  return [...cur, user._id];
                }
              });
            }}
          />
        </TRBody>
      )}
      <TRBody
        className={`whitespace-nowrap !py-3 !pl-4 !pr-3 text-sm ${hasNoHiddenItems.length > 0 ? "" : "!cursor-not-allowed"}`}
        onClick={handleOnClick}>
        <div className="flex items-center">
          <div className="hidden h-10 w-10 flex-shrink-0 overflow-hidden rounded-full sm:block">{<UserProfile user={user || {}} />}</div>
          <div className="sm:ml-4">
            <div className="flex items-center gap-x-2 font-medium text-gray-900">
              {authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) && hasNoHiddenItems.length > 0 ? (
                <Link
                  to={user?.type === "admin" ? `?user=${user._id}` : `?user=${user._id}`}
                  className="font-medium text-gray-900 hover:underline">
                  {user?.name}
                </Link>
              ) : (
                user?.name
              )}

              {user?.type === "admin" && <span className="rounded-full bg-gray-100 px-2 py-[1px] text-gray-400">{user?.default_user && <span>Super</span>} Admin</span>}
            </div>

            <div className="text-gray-500">
              {insertTypes[user.creation_type]} created {formatDate(user.created_at, "MM/DD/YYYY")}
            </div>
          </div>
        </div>
      </TRBody>
      <TRBody
        className={`hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 sm:table-cell ${hasNoHiddenItems.length > 0 ? "" : "!cursor-not-allowed"}`}
        onClick={handleOnClick}>
        <div className="text-gray-900">{user?.email}</div>
      </TRBody>
      <TRBody className="hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 lg:table-cell">
        <div
          className={classNames(
            "flex !h-6 w-[60px] items-center justify-center rounded-full px-2 text-xs font-semibold leading-5",
            activeStatus ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500",
            me?.type !== "admin" ? "pointer-events-none cursor-default" : "",
          )}>
          {activeStatus ? "Active" : "Inactive"}
        </div>
        {/* TODO: Inactive users should be grayed out: Only inform about about inactive users */}
        {/* <>{activeStatus ? "" : <div className={classNames("flex !h-6 w-[60px] items-center justify-center rounded-full bg-gray-50 px-2 text-xs font-semibold leading-5 text-red-400", me?.type !== "admin" ? "pointer-events-none cursor-default" : "")}>Inactive</div>}</> */}
      </TRBody>
      {workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
        <TRBody className="hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 lg:table-cell">
          <SelectMenu
            options={roles}
            setOption={(e) => manageUserRole(e, user)}
            startIndex={roles.findIndex((rl) => rl.value === user.type)}
          />
        </TRBody>
      )}
      <TRBody className="relative flex justify-end whitespace-nowrap !py-3 !pl-3 !pr-4 text-sm font-medium sm:!pr-6">
        <QuickMenu
          items={quickMenuItems}
          disabled={!hasNoHiddenItems.length}
        />
      </TRBody>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus, getAuthorizedUser, resetWorkspaceDetails })(UserEntry);
