import EmailTemplateSettings from "./EmailTemplates/EmailTemplateSettings";
import SiteEmailSettings from "./SiteEmailSettings";
import SmtpSettings from "./EmailDomain/SmtpSettings";
import React from "react";

const SiteEmailManager = ({ workspaceId = null }) => {
  return (
    <div className="grid gap-y-4">
      {!workspaceId && <EmailTemplateSettings />}
      <SiteEmailSettings workspaceId={workspaceId} />
      <SmtpSettings workspaceId={workspaceId} />
    </div>
  );
};

export default SiteEmailManager;
