import { ArrowTopRightOnSquareIcon, RectangleStackIcon, SquaresPlusIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { manageUserRole } from "src/actions/user";
import { getUserWorkspaceData } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import Accordion from "src/components/Shared/Accordions/Accordion";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Image from "src/components/Shared/Image";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceGroupList from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupList";
import WorkspaceIndividualPermission from "src/components/Workspaces/Workspace/Groups/WorkspaceIndividualPermission";
import useMounted from "src/hooks/useMounted";

const UserWorkspaceList = ({ userDetails = {}, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [assignWorkspaceDisabled, setAssignWorkspaceDisabled] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];

  const isMounted = useMounted();

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const loadData = async () => {
    setLoaded(false);
    const getWorkspaces = await props.getUserWorkspaceData({ user_id: userDetails?._id });
    if (isMounted.current) {
      setWorkspaces(getWorkspaces);
      setLoaded(true);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?._id]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {
        body: {
          includes: ["groups"],
        },
      });
      const data = res.data;
      if (isMounted.current && data.status === 200) {
        setAllWorkspaces(data?.data || []);
      }
    };
    loadWorkspaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedWorkspace(workspaces.map((wrk) => wrk._id));
  }, [workspaces]);

  const manageUserRole = async (e, workspace_id, user) => {
    try {
      const data = await props.manageUserRole({
        id: user.id,
        type: e.value,
        auth_token: props.workspaceDetails?.auth_token,
        workspace_id,
      });
      toast.success(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelectedWorkspace = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  const userAssignToWorkspace = async () => {
    setAssignWorkspaceDisabled(true);

    try {
      const res = await apiRequest("post", `/users/workspace-assign`, {
        body: {
          user_id: userDetails?._id,
          workspaces: selectedWorkspace,
        },
      });
      const data = res.data;
      if (data.status === 200) {
        setWorkspaceModalOpen(false);
        loadData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setAssignWorkspaceDisabled(false);
    }
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        // console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  return (
    <>
      <Section className="-mx-2 sm:-mx-4">
        <div className="grid w-full gap-y-10">
          <div className="flex w-full items-center">
            <H3 caption="View and assign users to have access to individual pages and groups.">Assign Workspaces</H3>

            {props?.me?.type === "admin" && (
              <Button
                version="secondary"
                onClick={() => {
                  setWorkspaceModalOpen(true);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: RectangleStackIcon }}
                />
              </Button>
            )}
          </div>
          <hr className="w-full" />
          <div className="flex flex-col gap-y-4">
            {loaded ? (
              workspaces.length > 0 && (
                <div className="relative grid w-full gap-y-4 rounded-xl bg-transparent">
                  {workspaces.map((workspace) => (
                    <Accordion
                      isCollapsible={workspace?.workspace_type === "IFRAME_EMBED" ? true : false}
                      key={workspace._id}
                      headerContent={() => (
                        <div className="flex w-full flex-wrap items-center gap-4 sm:gap-10">
                          <div className="mr-auto flex items-center gap-4 gap-x-6">
                            <div
                              style={!workspace?.square_logo && workspace.image_logo ? { background: workspace.top_bar_color } : {}}
                              className="relative h-10 w-10 overflow-hidden rounded">
                              <Image
                                image={workspace.square_logo || workspace.image_favicon || workspace.image_logo}
                                alt={"User workspace"}
                              />
                            </div>
                            <div>
                              <div className="flex items-center gap-x-2 rounded px-2 py-1 text-base font-semibold text-gray-800">{workspace?.name}</div>
                            </div>
                            <Link to={`/workspaces/${workspace?._id}`}>
                              <div className="flex items-center gap-x-2 rounded px-2 py-1 text-base text-gray-300 transition-all hover:bg-gray-50/80 hover:text-gray-400">
                                Navigate to <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-2" />
                              </div>
                            </Link>
                          </div>
                          {workspace?.workspace_type !== "IFRAME_EMBED" && (
                            <div className="flex flex-wrap gap-2 sm:order-2">
                              <div className="flex h-6 items-center justify-center rounded-full border border-highlightColor bg-white px-2 text-xs font-medium text-gray-500">Platform Embed</div>
                            </div>
                          )}
                          {workspace?.workspace_type === "JWT_FULL_EMBED" && (
                            <div className="ml-auto flex items-center gap-2 sm:order-1 sm:mx-2">
                              <SelectMenu
                                classes={"!w-32"}
                                inline={true}
                                label={"Domo Role"}
                                options={roles}
                                setOption={(e) => manageUserRole(e, workspace._id, workspace?.user)}
                                startIndex={roles.findIndex((rl) => rl.value === workspace?.user?.type)}
                              />
                            </div>
                          )}
                        </div>
                      )}>
                      <div className="px-4 py-4 sm:px-6 sm:py-6">
                        <Tabs
                          changeParams={false}
                          tabs={[
                            {
                              name: "Groups",
                              icon: SquaresPlusIcon,
                              component: (
                                <WorkspaceGroupList
                                  workspaceId={workspace._id}
                                  workspaceDetails={workspace}
                                  pageName={"user"}
                                  userId={userDetails?._id}
                                  viewUsers={false}
                                  viewOnly={true}
                                  userDetails={userDetails}
                                  workspace={allWorkspaces.find((wrk) => wrk._id === workspace._id)}
                                />
                              ),
                            },
                            {
                              name: "Individual",
                              icon: WindowIcon,
                              component: (
                                <WorkspaceIndividualPermission
                                  allTrustedAttributes={allTrustedAttributes}
                                  workspaceId={workspace._id}
                                  userId={userDetails?._id}
                                />
                              ),
                            },
                          ]}></Tabs>
                      </div>
                    </Accordion>
                  ))}
                </div>
              )
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </Section>

      <Modal
        title={`Assign Workspace`}
        isOpen={workspaceModalOpen}
        onCancel={() => setWorkspaceModalOpen(false)}
        onSuccess={userAssignToWorkspace}
        defaultStyles={{
          overFlowYVisible: true,
        }}
        defaultOptions={{
          onSuccessLoaderStart: assignWorkspaceDisabled,
          onSuccessLoaderVisible: assignWorkspaceDisabled,
        }}>
        <div className="relative min-h-[200px] py-4">
          <MultiSelectObject
            defaultOptions={allWorkspaces?.map((workspace) => {
              return { key: workspace._id, value: workspace.name, selected: selectedWorkspace.includes(workspace?._id), object: workspace };
            })}
            searchableFields={["name"]}
            title="Assign workspace"
            onChange={handleSelectedWorkspace}
            inline={true}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserWorkspaceData, manageUserRole })(UserWorkspaceList);
