import { ArrowUturnLeftIcon, CheckCircleIcon, LinkIcon, PlusIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Image from "src/components/Shared/Image";
import Members from "src/components/Shared/Members";
import QuickMenu from "src/components/Shared/QuickMenu";
import WorkspaceGroupAuthorizeUserComponent from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupAuthorizeUserComponent";
import { noFavicon } from "src/config/host";
import { isSVGString } from "src/helpers";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import { v4 } from "uuid";

const WorkspaceGroupItem = ({
  viewOnly = false,
  defaultGroup = {},
  refreshGroups = () => {},
  pageOptions = [],
  operatorOptions = [],
  workspaceId = null,
  workspaceDetails = {},
  trustedAttributeArr = [],
  setSelectedGroup = () => {},
  setGroupChangeWarning = () => {},
  setDeleteGroup = () => {},
  setPublicGroup = () => {},
  setCloneGroup = () => {},
  setManageTagGroup = () => {},
  setAddIconGroup = () => {},
  userModalOpen = () => {},
  setGroups = () => {},
  groupChanges = 0,
  setGroupChanges = () => {},
  pagePreview = () => {},
  setScheduleReports = () => {},
  setFlattenPages = () => {},
  listToggle,
  setListToggle,
  activeSSO,
  ...props
}) => {
  const groupNameInputRef = useRef(null);
  const [items, setItems] = useState([]);
  const [group, setGroup] = useState({});
  const [name, setName] = useState("");
  const [toggleNameEdit, setToggleNameEdit] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const pageJson = {
    id: v4(),
    _id: null,
    page_id: null,
    filters: [],
    is_draft: true,
  };

  useOnClickOutside(groupNameInputRef, () => setToggleNameEdit(false));

  useEffect(() => {
    setName(defaultGroup?.name);
  }, [defaultGroup?.name]);

  useEffect(() => {
    if (
      defaultGroup?.public &&
      defaultGroup?.frontUrl &&
      defaultGroup?.permissions?.length > 0 &&
      (workspaceDetails?.slug || workspaceDetails?._id) &&
      (defaultGroup?.slug || defaultGroup?._id) &&
      (defaultGroup?.permissions[0]?.page_slug || defaultGroup?.permissions[0]?.page_id)
    ) {
      let baseUrl = defaultGroup?.frontUrl;
      if (props?.site?.manage_subdomains && props?.site?.allow_subdomains && props?.singleWorkspaceDetail?.domain) {
        baseUrl = `https://${props?.singleWorkspaceDetail?.domain}`;
      }
      setPublicUrl(`${baseUrl}/workspace/${workspaceDetails?.slug || workspaceDetails?._id}/embed?group=${defaultGroup?.slug || defaultGroup?._id}&page=${defaultGroup?.permissions[0]?.page_slug || defaultGroup?.permissions[0]?.page_id}`);
    } else {
      setPublicUrl("");
    }
  }, [defaultGroup?.public, defaultGroup?.frontUrl, workspaceDetails?.slug, workspaceDetails?._id, defaultGroup?.slug, defaultGroup?._id, defaultGroup?.permissions, props?.singleWorkspaceDetail?.domain, props?.site?.manage_subdomains, props?.site?.allow_subdomains]);

  // *** FUNCTIONS ***
  const saveGroupChanges = async () => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/groups/:group_id`,
        {
          body: {
            ...group,
            name,
            permissions: group.permissions,
          },
          params: { workspace_id: workspaceId, group_id: group._id },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setSelectedGroup({
        ...group,
        filters: group?.filters?.map((filter) => ({
          ...filter,
        })),
        permissions: group.permissions.map((permission, index) => {
          let newData = data.data.find((dt, i) => i === index);
          return {
            ...permission,
            datasource_id: permission?.datasource_id?.trim(),
            page_alias: permission?.page_alias?.trim(),
            displayNameShow: permission?.page_alias?.trim() ? true : false,
            _id: newData?._id,
          };
        }),
      });
      refreshGroups();
      setGroupChanges(0);
    } catch (error) {}
  };

  const onClear = () => {
    setGroup({
      ...defaultGroup,
      filters: defaultGroup?.filters?.map((filter) => ({
        ...filter,
      })),
      permissions: defaultGroup?.permissions?.map((permission) => {
        return {
          ...permission,
          displayNameShow: permission?.page_alias ? true : false,
          displayNameChanges: true,
          filters: permission.filters.map((filter) => {
            return {
              ...filter,
            };
          }),
        };
      }),
    });
    setName(defaultGroup?.name);
    setGroupChanges(0);
    setToggleNameEdit(false);
  };

  const addFilter = async () => {
    const uniqueId = v4();
    const filter = {
      _id: uniqueId,
      operator_id: null,
      column_name: null,
      value_type: "value",
      column_value: null,
      trusted_attribute: null,
      datasource_id: null,
    };
    setGroup({ ...group, filters: group?.filters ? [...group.filters, filter] : [filter] });
    setGroupChanges(groupChanges + 1);
  };

  // *** useEffect TRIGGERS ***
  useEffect(() => {
    setToggleNameEdit(false);
    setName(defaultGroup.name || "");
    setGroup({
      ...defaultGroup,
      filters: defaultGroup?.filters?.map((filter) => ({
        ...filter,
      })),
      permissions: defaultGroup?.permissions?.map((permission) => {
        return {
          ...permission,
          id: v4(),
          displayNameShow: permission?.page_alias ? true : false,
          displayNameChanges: true,
          filters: permission.filters.map((filter) => {
            return {
              ...filter,
            };
          }),
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultGroup]);

  useEffect(() => {
    let defaultItems = [];
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"], true)) {
      defaultItems.push({
        name: "Add page",
        onClick: () => {
          setGroup({ ...group, permissions: [...group.permissions, pageJson] });
        },
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"], true) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["user_update"], true)) {
      defaultItems.push({
        name: "Manage users",
        onClick: () => userModalOpen(true, group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        name: `Schedule as Report`,
        onClick: () => setScheduleReports(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"], true)) {
      defaultItems.push({
        name: "Add group filter",
        onClick: () => {
          addFilter();
        },
      });
    }
    if (activeSSO?.isSSOActive && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        name: `Add tags`,
        onClick: () => setManageTagGroup(group),
      });
    }
    if (!toggleNameEdit && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        name: `Change title`,
        onClick: () => setToggleNameEdit(true),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        name: `Flatten Foldering`,
        icon: group?.flatten_pages ? <CheckCircleIcon className="ml-1 flex h-5 w-5 text-highlightColor" /> : "",
        onClick: () => setFlattenPages(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        name: `${group?.image ? "Edit" : "Add"} icon`,
        onClick: () => setAddIconGroup(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        type: "hr",
      });
      defaultItems.push({
        name: `${group?.public ? "Remove" : "Allow"} public access`,
        onClick: () => setPublicGroup(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      defaultItems.push({
        type: "hr",
      });
      defaultItems.push({
        name: "Clone group",
        onClick: () => setCloneGroup(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) {
      if (defaultItems.length > 0)
        defaultItems.push({
          type: "hr",
        });

      defaultItems.push({
        name: "Delete group",
        onClick: () => setDeleteGroup(group),
        color: "text-red-500",
      });
    }

    setItems(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me, group, workspaceDetails?.workspace_type, toggleNameEdit]);

  const assignDisplayName = async (permission) => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/groups/:group_id/display-name`,
        {
          body: {
            permission: permission,
          },
          params: { workspace_id: workspaceId, group_id: group._id },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setSelectedGroup((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.map((p, index) => {
          if (index === permission?.ordering) {
            return {
              ...p,
              _id: data?.data?._id,
              displayNameChanges: true,
              page_alias: "",
            };
          } else {
            return p;
          }
        }),
      }));
    } catch (error) {}
  };

  const handleChange = async (e) => {
    setName(e.target.value);
    setGroupChanges(groupChanges + 1);
  };

  const handleSubmit = () => {
    if (groupChanges > 0) {
      saveGroupChanges();
    }
  };

  useEffect(() => {
    if (toggleNameEdit && groupNameInputRef.current) {
      groupNameInputRef.current.focus(); // Focus the input when it is rendered
    }
  }, [toggleNameEdit]);

  // useEffect(() => {}, [group?.permission]);

  return (
    <>
      {listToggle && (
        <div className={classNames("flex w-full transition-all lg:hidden", listToggle ? "mb-3 h-auto opacity-100" : "h-0 overflow-hidden opacity-0")}>
          <Button
            version="primary"
            onClick={() => setListToggle(false)}>
            <ArrowUturnLeftIcon className="h-5 w-5" />
            Back
          </Button>
        </div>
      )}

      <div className="relative flex w-full max-w-full flex-col rounded-md border border-gray-200 shadow lg:min-h-[400px]">
        <div className="relative z-[1] flex w-full min-w-0 items-center justify-between gap-10 rounded-t-md px-4 pt-4">
          <div className="relative flex w-full min-w-0 items-center gap-x-2 overflow-hidden">
            {group.image && (
              <div className="mb-2 mr-2 h-10 w-10 flex-shrink-0 overflow-hidden p-1 py-2">
                {isSVGString(group.image) ? (
                  <div dangerouslySetInnerHTML={{ __html: group.image }} />
                ) : (
                  <Image
                    image={group.image}
                    failImage={noFavicon}
                    alt={"Groups"}
                  />
                )}
              </div>
            )}
            {workspaceDetails?.workspace_type === "IFRAME_EMBED" ? (
              <div className="group relative flex w-full min-w-0 cursor-default items-center overflow-hidden">
                {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly ? (
                  <div className="relative w-full min-w-0 overflow-hidden">
                    <Input
                      inputRef={groupNameInputRef}
                      type="text"
                      autoComplete="on"
                      name="group-name"
                      value={name}
                      onChange={handleChange}
                      wrapperClass={"!shadow-none !w-full"}
                      inputClassNames="w-full max-w-[400px] md:max-w-[600px] xl:max-w-[800px] pr-4 focus:ring-0 border-transparent focus:border-highlightColor bg-transparent hover:border hover:border-gray-200 flex items-center gap-x-2 text-xl font-semibold leading-none px-2  rounded transition-all duration-75 text-gray-800 truncate"
                    />
                  </div>
                ) : (
                  <p className="truncate font-semibold text-gray-700">{group.name}</p>
                )}
              </div>
            ) : (
              <p className="truncate font-semibold text-gray-700">{group.name}</p>
            )}
          </div>

          <div className="flex w-[350px] min-w-0 items-center justify-end">
            {!viewOnly && (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"])) && groupChanges > 0 && (
              <div className={classNames("flex w-full justify-end gap-x-4 rounded transition-all", groupChanges > 0 ? "h-auto border-gray-200 bg-gray-50/50 px-3 opacity-100" : "h-0 overflow-hidden border-transparent opacity-0")}>
                <Button
                  version="gray"
                  onClick={onClear}
                  disabled={groupChanges < 1}>
                  Undo
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={groupChanges < 1}>
                  Save
                </Button>
              </div>
            )}
            <div className="flex min-w-0 items-center justify-end gap-x-3">
              {defaultGroup._id &&
                (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["user_update"])) &&
                !viewOnly && (
                  <QuickMenu
                    position="LEFT"
                    items={items}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="hidden items-center justify-start gap-x-4 px-6 pb-2 sm:flex">
          {group.users?.length > 0 && !props?.userId && (
            <div
              onClick={() => {
                if (!viewOnly) {
                  userModalOpen(true, group);
                }
              }}
              className={classNames("relative flex gap-x-3 py-2", !viewOnly ? "cursor-pointer hover:underline" : "")}>
              {group.users?.length > 0 && (
                <Members
                  className="!w-auto !justify-start"
                  group={{
                    ...group,
                    users: props?.userId ? group.users.filter((user) => user?._id === props?.userId) : group.users,
                  }}
                />
              )}
            </div>
          )}
          {group.permissions?.length > 0 && (
            <>
              {/* {group.users?.length > 0 && !props?.userId && <div className="ml-4 mr-2 hidden h-[4px] w-[4px] rounded-full bg-gray-400 md:flex" />} */}
              <div className="hidden overflow-hidden md:flex">
                <div className={classNames("relative hidden items-center gap-x-1 rounded border-transparent px-2 py-2 text-sm text-gray-400 sm:flex")}>
                  <WindowIcon className="h-6 w-6" />
                  {group.permissions?.length} Pages
                  {/* <p className={classNames("absolute bottom-0 flex h-5 min-w-5 items-center justify-center rounded-full border-[1px] border-gray-100 bg-white p-1", group.permissions?.length > 10 ? "-right-2" : "right-0")}>{group.permissions?.length}</p> */}
                </div>
              </div>
            </>
          )}
          {workspaceDetails?.workspace_type === "IFRAME_EMBED" ? (
            publicUrl && (
              <div className="flex">
                <CopyToClipboard
                  text={publicUrl}
                  onCopy={() => toast.success("Copied")}>
                  <div className="ml-3 flex cursor-pointer items-center justify-center gap-x-2 text-highlightColor opacity-80 transition-all hover:underline hover:opacity-100">
                    <div className="min-w-[85px] flex-grow text-sm">Copy public link</div>
                    <div className="flex flex-shrink-0 items-center justify-center rounded-full">
                      <LinkIcon className="h-4 w-4 stroke-2" />
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            )
          ) : (
            <div />
          )}
        </div>

        <div className="relative h-full flex-grow border-t border-gray-200 px-4 py-4 lg:px-2 lg:py-2 xl:px-4 xl:py-4">
          <WorkspaceGroupAuthorizeUserComponent
            group={group}
            permissions={group?.permissions || []}
            setGroup={setGroup}
            groupChanges={groupChanges}
            setGroupChanges={setGroupChanges}
            me={props.me}
            pageOptions={pageOptions}
            operatorOptions={operatorOptions}
            viewOnly={viewOnly}
            trustedAttributeArr={trustedAttributeArr}
            workspaceDetails={workspaceDetails}
            workspaceId={workspaceId}
            addPermission={(e, index) => {
              setGroup((preData) => ({
                ...preData,
                permissions: preData.permissions.map((p, i) => {
                  if (i === index) {
                    return e;
                  } else {
                    return p;
                  }
                }),
              }));
            }}
            updatePermission={(updatedPermission) => {
              setGroup((preData) => ({
                ...preData,
                permissions: preData.permissions.map((oldPermission) => {
                  if (oldPermission._id === updatedPermission._id) {
                    return updatedPermission;
                  } else {
                    return oldPermission;
                  }
                }),
              }));
              setGroupChanges(groupChanges + 1);
            }}
            removePermission={(e, index) => {
              setGroupChanges(groupChanges + 1);
              setGroup({
                ...group,
                permissions: group.permissions.filter((p, i) => i !== index),
              });
            }}
            pagePreview={pagePreview}
            assignDisplayName={assignDisplayName}
            authorizeUserComponentAccessPermission={authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])}
          />
        </div>
        {group?._id && !viewOnly && (authorizeUserComponentAccess(props.me, workspaceId, "group", ["content_page_update"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) && (
          <div className="flex h-full w-full items-center justify-between p-4">
            <Button
              version="secondary"
              onClick={() => setGroup({ ...group, permissions: [...group.permissions, pageJson] })}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              Add page
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
    singleWorkspaceDetail: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupItem);
