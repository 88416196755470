import { PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import useFetch from "src/hooks/useFetch";
import useMounted from "src/hooks/useMounted";

const GlobalFiltersList = () => {
  const user = useSelector((state) => state.auth.user);
  const [filters, setFilters] = useState([]);
  const [mainFilter, setMainFilters] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isFilterLoaded, setIsFilterLoaded] = useState(false);

  const isMounted = useMounted();

  // const { id: userId } = useParams();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user");

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const {
    response: { data: operators = [] },
  } = useFetch("/operators/list");

  useEffect(() => {
    if (userId) {
      const loadFilters = async () => {
        try {
          const res = await apiRequest("get", `users/:user_id/global-filters`, {
            params: { user_id: userId },
          });
          if (isMounted.current) {
            setFilters(res.data.data || []);
            setMainFilters(res.data.data || []);
          }
        } catch (error) {
        } finally {
          setIsFilterLoaded(true);
        }
      };

      loadFilters();
    }
  }, [userId, isMounted]);

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (isMounted.current && resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFilter = async () => {
    setFilters((filters) => [
      ...filters,
      {
        operator_id: null,
        column_name: null,
        value_type: "value",
        column_value: null,
        trusted_attribute: null,
        datasource_id: null,
      },
    ]);
  };

  const handleOnSave = async () => {
    try {
      setDisabled(true);
      const res = await apiRequest("post", `users/:user_id/global-filters`, {
        body: { filters },
        params: { user_id: userId },
      });
      setMainFilters(filters);
      toast.success(res.data.message);
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  const filterButton = (
    <Button
      version="default"
      onClick={addFilter}
      className="!h-10 text-highlightColor">
      <div className="flex items-center gap-x-3">
        <PlusIcon className="h-5 w-5 stroke-2" /> Add data access
      </div>
    </Button>
  );

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  return (
    <EditContainer
      title="Data Access"
      subtitle="Conditions created here will be applied to all dashboards that the user has been given access to see in all workspaces."
      preview={{
        text: filters.length ? (
          <>
            <div>{filters.length}</div>
            Applied Data Access
            {filters.length > 1 && "s"}
          </>
        ) : (
          "No data access"
        ),
      }}
      defaultOpen={true}
      isLoading={disabled}
      onSuccess={handleOnSave}
      onCancel={() => setFilters(mainFilter)}
      defaultOptions={{
        onCancelButtonVisible: isFilterLoaded,
        onSuccessButtonVisible: isFilterLoaded,
      }}
      fullWidth={true}>
      <>
        <div className="relative">
          <p className="mb-2">Manage the user's data access from one location.</p>
          <div className="mt-2 space-y-3 rounded bg-white pb-3">
            {filters.length > 0 ? (
              <>
                {filters.map((filter, index) => {
                  return (
                    <WorkspaceIntegrationFiltersItem
                      from={"dataAccessSettings"}
                      key={index}
                      filters={filters}
                      user={user}
                      workspaceId={null}
                      state={filter}
                      operators={operators}
                      trustedAttributeArr={allTrustedAttributes}
                      setState={(e) => {
                        setFilters(
                          filters.map((fl, i) => {
                            if (index === i) {
                              return e;
                            } else {
                              return fl;
                            }
                          }),
                        );
                      }}
                      removeFilter={() => {
                        setFilters((prevData) => prevData.filter((data, i) => i !== index));
                      }}
                    />
                  );
                })}
                <div className="flex">{filterButton}</div>
              </>
            ) : isFilterLoaded ? (
              <NoDataDashedContainer addButton={filterButton} />
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </>
    </EditContainer>
  );
};

export default GlobalFiltersList;
