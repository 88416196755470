import { useEffect, useState } from "react";
import { noProfile } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { formatName } from "src/helpers/formatName";
import { getS3ImageDetails } from "src/helpers/image";

export default function UserProfile({ user, className = "", size = "md" }) {
  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null,
  });

  const [invalidImage, setInvalidImage] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    if (user?.image && !user.image.includes("data:image/")) {
      setInvalidImage(false);

      const loadS3Image = async () => {
        setS3Image((data) => ({ ...data, loading: true }));

        const res = await getS3ImageDetails(user.image, ac.signal);
        if (res.status === 200) {
          setInvalidImage(false);
          setS3Image({ loading: false, data: res.data });
        }
      };

      loadS3Image();
    } else {
      setInvalidImage(true);
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  return (
    <>
      {user?.image && s3Image.loading ? (
        <div className={classNames("relative h-full w-full flex-shrink-0 ", className)}>
          <img
            className={classNames("h-full w-full object-cover", "opacity-40")}
            src={noProfile}
            alt="no-profile"
          />
        </div>
      ) : (
        <>
          {invalidImage || (!s3Image.loading && !s3Image.data) ? (
            <div className={`flex h-full w-full flex-shrink-0 items-center justify-center rounded-full text-gray-900 text-${size} bg-gray-200 p-3 uppercase ` + className}>{formatName(user?.name, user?.email)}</div>
          ) : (
            <div className={"relative h-full w-full flex-shrink-0" + className}>
              <img
                className={classNames("h-full w-full object-cover", user?.image && s3Image.data ? "opacity-100 transition-opacity duration-150" : "opacity-40")}
                src={s3Image.loading ? noProfile : s3Image.data}
                alt="no-profile"
                onError={() => setInvalidImage(true)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
