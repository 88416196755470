import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Buttons/Button";

export default function SlideOver({ isOpen = false, handleClose = () => {}, title = "", description = "", maxWidth = "max-w-2xl", children }) {
  const [open, setOpen] = useState(isOpen);
  const [isGiantScreen, setIsGiantScreen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsGiantScreen(window.innerWidth > 1920); // Adjust breakpoint for "giant" screens as needed
    };

    handleResize(); // Check on component mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnClose = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[30]"
        onClose={handleClose}>
        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={classNames("pointer-events-none fixed inset-y-0", isGiantScreen ? "left-0 flex max-w-full pr-10 sm:pr-16" : "right-0 flex max-w-full pl-10 sm:pl-16")}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-300"
                enterFrom={isGiantScreen ? "-translate-x-full" : "translate-x-full"}
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo={isGiantScreen ? "-translate-x-full" : "translate-x-full"}>
                <Dialog.Panel className={classNames("pointer-events-auto w-screen", maxWidth)}>
                  <>
                    <div className={classNames("flex h-full flex-col overflow-y-scroll bg-white shadow-xl", isGiantScreen ? "rounded-tr-xl" : "md:rounded-tl-xl")}>
                      <div className="flex-1">
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{title}</Dialog.Title>
                              <p className="text-sm text-gray-500">{description}</p>
                            </div>
                            <div className="flex h-7 items-center">
                              <Button
                                type="button"
                                version="default"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={handleOnClose}>
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="p-7">{children}</div>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
