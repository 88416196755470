import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import NormalInput from "src/components/Shared/Forms/Inputs/Input";
import * as Yup from "yup";
import Stepper from "src/components/Shared/Stepper/Stepper";
import TestEmailSetup from "./TestEmailSetup";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";

const EmailOverview = ({ settings = null, isEnableTestConnectionButton = false, setCurrentStep = () => {}, onTestEmailModalOpen = () => {} }) => (
  <div className="my-2 flex w-full max-w-2xl items-center gap-4">
    <div className="relative w-[70%]">
      <NormalInput
        name="test-email"
        disabled
        value={settings?.smtp_email || ""}
        label="SMTP APPROVED EMAIL"
        inline={true}
      />
    </div>

    <div className="relative flex flex-shrink-0 gap-4">
      <Button
        type="button"
        className="!px-5"
        onClick={() => setCurrentStep(0)}
        version="secondary">
        Edit
      </Button>
      {isEnableTestConnectionButton && (
        <Button
          className=""
          onClick={onTestEmailModalOpen}
          type="button">
          Test Connection
        </Button>
      )}
    </div>
  </div>
);

const FinalStep = ({ settings, setCurrentStep = () => {}, onTestEmailModalOpen = () => {} }) => (
  <>
    <EmailOverview
      settings={settings}
      setCurrentStep={setCurrentStep}
      isEnableTestConnectionButton={true}
      onTestEmailModalOpen={onTestEmailModalOpen}
    />
    <TextAccordion
      headerTextPosition="left"
      headerText="See SMTP details">
      <div className="grid space-y-2">
        <div className="w-full sm:w-1/2">
          <NormalInput
            disabled
            value={settings?.smtp_host}
            label="Smtp Host"
            inline={true}
          />
        </div>
        <div className="flex w-full flex-col gap-3 sm:flex-row">
          {/* SMTP port */}
          <div className="relative w-full sm:w-1/2">
            {/* SMTP secure */}
            <div className="absolute right-4 top-2 z-30 ml-2 flex cursor-pointer items-center gap-x-2 text-xs text-gray-700 opacity-70">
              <input
                disabled
                className="cursor-pointer rounded text-gray-300 focus:ring-highlightColor"
                type="checkbox"
                checked={settings?.smtp_secure}
              />
              <label className="cursor-pointer">SMTP Secure</label>
            </div>
            <div className="w-full">
              <NormalInput
                disabled
                value={settings?.smtp_port}
                label="Smtp Port"
                inline={true}
              />
            </div>
          </div>
        </div>
      </div>
    </TextAccordion>
  </>
);

const SelfHostedSmtpSettings = ({ workspaceId = null, initialSettings, onTestEmailModalOpen = () => {}, ...props }) => {
  const steps = ["SMTP Setup", "Test Setup"];

  const [settings, setSettings] = useState(initialSettings);
  const [smtpEmail, setSmtpEmail] = useState("");
  const [smtpHost, setSmtpHost] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [smtpSecure, setSmtpSecure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const initializeValue = () => {
    setSmtpEmail(settings?.smtp_email || "");
    setSmtpHost(settings?.smtp_host || "");
    setSmtpUser(settings?.smtp_user || "");
    setSmtpPassword(settings?.smtp_password || "");
    setSmtpPort(settings?.smtp_port || "");
    setSmtpSecure(settings?.smtp_secure);
  };

  const getValidationSchema = ({ prevSmtpPassword }) => {
    return Yup.object().shape({
      smtpEmail: Yup.string().required("Required"),
      smtpHost: Yup.string().required("Required"),
      smtpUser: Yup.string().required("Required"),
      smtpPort: Yup.number().required("Required"),
      smtpSecure: Yup.boolean().required("Required"),
      smtpPassword: prevSmtpPassword ? Yup.string().notRequired() : Yup.string().required("Required"),
    });
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);

      let body = {
        smtp_email: values.smtpEmail,
        smtp_host: values.smtpHost,
        smtp_user: values.smtpUser,
        smtp_port: values.smtpPort,
        smtp_secure: values.smtpSecure,
      };

      if (values.smtpPassword) body = { ...body, smtp_password: values.smtpPassword };

      const message = workspaceId ? await props.manageWorkspaceSettings({ workspace_id: workspaceId, ...body }, "/workspaces/:workspace_id/smtp/self-hosted") : await props.manageSiteSettings(body, "/settings/smtp/self-hosted");
      toast.success(message);
      setCurrentStep(1);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (settings) {
      if (settings?.smtp_self_hosted_verified) {
        setCurrentStep(2);
      } else {
        setCurrentStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    setSettings(workspaceId ? props?.workspaceDetails : props?.site);
  }, [workspaceId, props?.workspaceDetails, props?.site]);

  useEffect(() => {
    initializeValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const initialValues = {
    smtpEmail,
    smtpHost,
    smtpUser,
    smtpPassword: "",
    smtpPort,
    smtpSecure,
    prevSmtpPassword: smtpPassword, // Assuming smtpPassword is the previous password
  };

  return (
    <div className="pt-2">
      <div className="grid gap-y-6">
        <div className="">
          <Stepper
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        {currentStep === 0 && (
          <div className="mt-3">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={(values) => {
                try {
                  // Dynamically generate the validation schema
                  const schema = getValidationSchema({
                    prevSmtpPassword: values.prevSmtpPassword,
                  });

                  // Validate the current values against the schema
                  schema.validateSync(values, { abortEarly: false });
                  return {}; // Return no errors if validation passes
                } catch (error) {
                  // Return errors in Formik-compatible format
                  const errors = {};
                  if (error.inner) {
                    error.inner.forEach((err) => {
                      if (!errors[err.path]) {
                        errors[err.path] = err.message;
                      }
                    });
                  }
                  return errors;
                }
              }}
              onSubmit={async (values) => {
                onSubmit(values);
              }}>
              {({ values, errors, touched, setFieldValue, resetForm }) => {
                return (
                  <Form>
                    <div className="grid space-y-4 sm:space-y-8">
                      <div className="relative flex w-full flex-wrap justify-between">
                        <div className="flex w-full flex-wrap gap-y-6">
                          {/* Contact email */}
                          <div className="grid w-full grid-cols-2 gap-3">
                            <div className="w-full">
                              <Input
                                name="smtpEmail"
                                label="SMTP email"
                                inline={true}
                              />
                            </div>
                            {/* SMTP host */}
                            <div className="w-full">
                              <Input
                                name="smtpHost"
                                label="Host"
                                inline={true}
                              />
                            </div>
                          </div>
                          <div className="grid w-full grid-cols-2 gap-3">
                            {/* SMTP username */}
                            <div className="w-full">
                              <Input
                                name="smtpUser"
                                label="User"
                                inline={true}
                              />
                            </div>
                            {/* SMTP password */}
                            <div className="w-full">
                              <Input
                                placeholder={smtpPassword}
                                name="smtpPassword"
                                label="Password"
                                type="password"
                                autoComplete="new-password"
                                inline={true}
                              />
                            </div>
                          </div>
                          <div className="grid w-full grid-cols-2 gap-3">
                            {/* SMTP port */}
                            <div className="relative w-full">
                              {/* SMTP secure */}
                              <div className="absolute right-4 top-2 z-30 ml-2 flex cursor-pointer items-center gap-x-2 text-xs text-gray-700 opacity-70">
                                <input
                                  className="cursor-pointer rounded text-gray-300 focus:ring-highlightColor"
                                  type="checkbox"
                                  checked={values.smtpSecure}
                                  name="smtpSecure"
                                  onChange={(e) => setFieldValue("smtpSecure", e.target.checked)}
                                />
                                <label className="cursor-pointer">SMTP Secure</label>
                              </div>
                              <div className="w-full">
                                <Input
                                  name="smtpPort"
                                  label="SMTP Port"
                                  inline={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-full flex-col items-end justify-between gap-4 sm:flex-row">
                        <div className="flex flex-shrink-0 justify-between gap-x-4 pt-2">
                          <Button
                            disabled={isLoading}
                            version="gray"
                            onClick={resetForm}>
                            Cancel
                          </Button>
                          <Button
                            disabled={isLoading}
                            isLoading={isLoading}
                            type="submit">
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {currentStep === 1 && (
          <TestEmailSetup
            workspaceId={workspaceId}
            type={"self_hosted"}
            additionalActions={
              <EmailOverview
                settings={settings}
                setCurrentStep={setCurrentStep}
              />
            }
          />
        )}
        {currentStep === 2 && (
          <FinalStep
            settings={settings}
            setCurrentStep={setCurrentStep}
            onTestEmailModalOpen={onTestEmailModalOpen}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(SelfHostedSmtpSettings);
