import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addUser, getGroupData } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import { H1 } from "src/components/Shared/Text/Headers";
import AddUserForm from "src/components/Users/Add/AddUserForm";

const ManageUserSection = ({ pages, operators, groups, addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [userState, setUserState] = useState({});
  const [initialWorkspaces, setInitialWorkspaces] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [initialWorkspaceGroups, setInitialWorkspaceGroups] = useState([]);
  const [workspaceGroups, setWorkspaceGroups] = useState([]);
  const [isRefresh] = useState(false);
  const [successButtonStart, setSuccessButtonStart] = useState(false);
  const [initialCustomFields, setInitialCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes("admins/add");

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setInitialCustomFields(
            resData.data.map((item) => ({
              ...item,
              value: item.default_value || "",
            })),
          );
          setCustomFields(
            resData.data.map((item) => ({
              ...item,
              value: item.default_value || "",
            })),
          );
        }
      } catch (err) {
        // console.dir("err", err);
      }
    })();
  }, []);

  // Load Workspaces
  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {
        body: { includes: ["groups", "users", "pages"] },
      });

      const data = res.data;

      if (data.status === 200) {
        setInitialWorkspaces(data.data.map((workspace) => ({ ...workspace, selected: false })));
        setWorkspaces(data.data.map((workspace) => ({ ...workspace, selected: false })));
      }
    };

    loadWorkspaces();
  }, []);

  // Load groups
  useEffect(() => {
    // const ac = new AbortController();

    if (workspaces.length) {
      const loadGroups = async () => {
        try {
          const res = await apiRequest("post", "/workspaces/domo-workspace-groups", { body: { workspaceIds: workspaces.map((item) => item._id) } });

          const data = res.data;

          if (data.status === 200) {
            setInitialWorkspaceGroups(data.data);
            setWorkspaceGroups(data.data);
          }

          // await props.getGroupData({}, ac.signal);
        } catch (error) {
          // console.dir('error', error);
        }
      };

      loadGroups();
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, workspaces.length]);

  useEffect(() => {
    loadCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    try {
      setSuccessButtonStart(true);

      let userData = [];

      Object.values(userState).forEach((user) => {
        userData.push({
          ...user,
          type: isAdmin ? "admin" : "user",
          custom_fields: customFields,
        });
      });

      await addUser({
        users: userData,
        workspaceIds: workspaces.filter((workspace) => workspace.selected).map((item) => item._id || item.key),
        groupIds: groups.filter((item) => item.selected).map((item) => item._id || item.id),
      });

      setSuccessButtonStart(false);

      if (isAdmin) {
        return navigate("/admins");
      } else {
        return navigate("/users");
      }
    } catch (error) {
      setSuccessButtonStart(false);
      toast.error(error.message);
    }
  };

  const clear = () => {
    setUserState({
      0: {
        confirm_password: "",
        email: "",
        groups: [],
        image: "",
        name: "",
        notifications: false,
        password: "",
        type: "user",
        update: true,
        disabled_sso_auto_updated: false,
        disabled_dataset_auto_updated: false,
      },
    });
    setWorkspaces([]);
    setTimeout(() => {
      setWorkspaces(initialWorkspaces.map((item) => ({ ...item, selected: false })));
    }, 500);
    setWorkspaceGroups([]);
    setTimeout(() => {
      setWorkspaceGroups(initialWorkspaceGroups.map((item) => ({ ...item, selected: false })));
    }, 500);
    setCustomFields(initialCustomFields);
  };
  return (
    <>
      <H1 item={{ icon: BackIndicator, onClick: () => navigate(searchParams.get("pathname")) }}>
        <p className="font-light capitalize text-gray-300">{isAdmin ? "admin" : "user"}s/</p>Create
      </H1>

      <Section>
        <div className="my-3 flex w-full items-center justify-between">
          <h3 className="pl-2 text-xl font-medium text-gray-500">Complete the form</h3>
        </div>
        {formCount.map((count, i) => {
          return (
            <AddUserForm
              key={i}
              isAdmin={isAdmin}
              index={count}
              formCount={formCount}
              setFormCount={setFormCount}
              state={userState}
              setState={setUserState}
              groups={workspaceGroups}
              workspaces={workspaces}
              setWorkspaces={setWorkspaces}
              customFields={customFields}
              setCustomFields={setCustomFields}
            />
          );
        })}

        <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
          <Button
            version="gray"
            type="button"
            onClick={clear}>
            Clear all
          </Button>
          <Button
            type="button"
            onClick={onSubmit}
            disabled={successButtonStart}
            loading={successButtonStart}>
            Submit
          </Button>
        </div>
      </Section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getGroupData, addUser })(ManageUserSection);
