import React, { useState } from "react";
import Tabs from "../Shared/Tabs/Tabs";
import { H1 } from "../Shared/Text/Headers";
import { WindowIcon } from "@heroicons/react/24/outline";
import PagePreview from "./PageModals/PagePreview";
import Button from "../Shared/Buttons/Button";
import { connect } from "react-redux";
import UserProfile from "../Users/UserProfile";
import Image from "../Shared/Image";

const PageEdit = ({ pageForm, pageDetails, isSliderOpen, ...props }) => {
  const [loadPreview, setLoadPreview] = useState(false);

  const tabs = [
    {
      name: "Page Details",
      icon: WindowIcon,
      component: (
        <div className="grid grid-cols-2 gap-4">
          {pageForm()}
          <div className="flex h-[calc(100vh-15rem)] flex-col overflow-hidden rounded-xl border shadow-md">
            <div
              style={{ background: props?.site?.top_bar_color, color: props?.site?.top_bar_text_color }}
              className="text flex flex-shrink-0 items-center  justify-between  px-2 py-1 text-lg">
              <div className="aspect-[2/0.5] w-[150px] overflow-hidden">
                <Image
                  image={props?.site?.image_logo}
                  alt="page_workspace_logo"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="flex items-center gap-2 ">
                <div className="text-sm ">{props?.user?.name}</div>
                <UserProfile
                  user={props?.user}
                  className={" !h-8 !w-8 overflow-hidden rounded-full text-xs"}
                />
              </div>
            </div>
            {/* <hr className="flex-shrink-0 pb-2" /> */}
            {loadPreview ? (
              <div
                style={{ zoom: 0.6 }}
                className="h-full flex-shrink flex-grow !rounded-b-xl">
                <PagePreview
                  isOpen={isSliderOpen}
                  pageDetails={pageDetails}
                />
              </div>
            ) : (
              <div className="flex h-full w-full justify-center pt-24">
                <Button
                  version="secondary"
                  onClick={() => setLoadPreview(true)}>
                  Load a preview
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
      visible: true,
    },
  ];

  return (
    <>
      <H1 className="flex-wrap gap-y-2 sm:flex-nowrap sm:gap-y-0">
        <p className="font-light capitalize text-gray-300">Pages/</p>
        <div className="flex items-center gap-2 pl-10 sm:pl-0">Edit</div>
      </H1>
      <Tabs
        queryParamName="userTabs"
        tabs={tabs}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(PageEdit);
