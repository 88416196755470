import { connect } from "react-redux";
import useIframeResize from "src/hooks/useIframeResize";

const DomoEmbedProgrammaticFiltering = ({ content, workspaceDetails, site, user }) => {
  useIframeResize({ workspaceDetails, site, user });

  // Define the conditional maxWidth value
  const conditionalMaxWidth = content?.embed_type === "EMBED_APP" ? "100%" : workspaceDetails?.max_width;

  return (
    <>
      <div className="relative h-full w-full">
        {content?.iframeHtml && (
          <div
            dangerouslySetInnerHTML={{ __html: content?.iframeHtml }}
            className="mx-auto h-full w-full overflow-auto"
            style={{ maxWidth: conditionalMaxWidth || "100%" }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(DomoEmbedProgrammaticFiltering);
