// prettier-ignore
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import TableRow from "src/components/Shared/Table/TableRow";
import { formatDate } from "src/helpers/formatDate";
import WorkspaceSubscriberInstance from "../Workspaces/WorkspaceSubscriberInstance";
import { formatCurrency } from "src/helpers";

const BasicActivityContent = ({ title = "", subTitles = [], noTitle = "" }) => {
  return title || subTitles ? (
    <div className="inline-flex flex-col text-xs text-gray-900">
      {title || ""}
      {subTitles &&
        subTitles?.length > 0 &&
        subTitles?.map(
          (text) =>
            text && (
              <div
                key={text}
                className="text-gray-400">
                {text}
              </div>
            ),
        )}
    </div>
  ) : (
    <div className="text-gray-400">*{noTitle || ""}*</div>
  );
};

function ActivityEntry({ me, activity, ...props }) {
  let { data, user_id } = activity;
  let { created_by_email, created_by_name, login_portal } = data;

  let primaryName,
    secondaryName = "";
  if (login_portal === "SYSTEM") {
    primaryName = "System Generated";
  } else if (created_by_name || created_by_email || user_id) {
    primaryName = created_by_name || user_id?.name || "N/A";
    secondaryName = created_by_email || user_id?.email || "No email listed";
  } else {
    if (activity?.public) {
      secondaryName = "*Publicly Accessed*";
    } else {
      secondaryName = "*User not found*";
    }
  }

  let title,
    subTitles,
    noTitle = "";
  let { type } = activity;

  let workspaceTypes = ["WORKSPACE_CREATE", "WORKSPACE_ORDERING", "WORKSPACE_FILTER_UPDATE", "WORKSPACE_DOMAIN_CREATE", "WORKSPACE_DOMAIN_DELETE", "WORKSPACE_DELETE", "LAYOUT_UPDATE"];
  let userTypes = [
    "USER_CREATE",
    "USER_CREATE_BY_SSO",
    "USER_UPDATE",
    "USER_UPDATE_BY_SSO",
    "USER_PASSWORD_CHANGE",
    "USER_SEND_RESET_PASSWORD_EMAIL",
    "USER_SEND_WELCOME_EMAIL",
    "USER_ASSIGN_TO_WORKSPACE",
    "USER_UNASSIGN_FROM_WORKSPACE",
    "USER_DOMO_ROLE_CHANGE",
    "USER_INDIVIDUAL_PERMISSION_UPDATE",
    "USER_ASSIGN_TO_GROUP",
    "USER_UNASSIGN_TO_GROUP",
    "USER_DATA_ACCESS_UPDATE",
    "USER_ASSIGN_TO_ADMIN",
    "ADMIN_ASSIGN_TO_USER",
    "USER_SSO_AUTO_UPDATE_DISABLE",
    "USER_SSO_AUTO_UPDATE_ENABLE",
    "USER_DATASET_AUTO_UPDATE_DISABLE",
    "USER_DATASET_AUTO_UPDATE_ENABLE",
    "USER_STATUS_CHANGE_TO_ACTIVE",
    "USER_STATUS_CHANGE_TO_INACTIVE",
    "USER_DELETE",
    "USER_DELETE_FROM_WORKSPACE",
    "EDITOR_ASSIGN_TO_WORKSPACE",
    "EDITOR_UNASSIGN_FROM_WORKSPACE",
    "EDITOR_PERMISSION_UPDATE",
  ];
  let pageTypes = ["PAGE_CREATE", "PAGE_UPDATE", "PAGE_DELETE", "PAGE_PREVIEW", "PAGE_VIEW"];
  let formTypes = ["FORM_CREATE", "FORM_UPDATE", "FORM_DELETE", "FORM_SETTINGS_UPDATE", "FORM_DATASET_UPDATE", "FORM_SUBMIT", "FORM_RESPONSE_PUSH_TO_DATASET"];
  let groupTypes = ["GROUP_CREATE", "GROUP_UPDATE", "GROUP_DELETE", "GROUP_CLONE", "GROUP_ICON_MANAGE", "GROUP_ORDERING", "GROUP_PUBLIC", "GROUP_PRIVATE", "GROUP_SCHEDULE_UPDATE"];
  let credentialTypes = ["CREDENTIAL_CREATE", "CREDENTIAL_UPDATE", "CREDENTIAL_DELETE", "CREDENTIAL_MAKE_DEFAULT", "CREDENTIAL_SET_CREATED_BY"];
  let ssoTypes = ["SSO_CREATE", "SSO_UPDATE", "SSO_DELETE", "SSO_STATUS_CHANGE_TO_ACTIVE", "SSO_STATUS_CHANGE_TO_INACTIVE", "SSO_MAP_TO_WORKSPACE_GROUP_UPDATE", "SSO_MAP_TO_USER_FIELDS_UPDATE"];
  let customFieldTypes = ["USER_CUSTOM_FIELD_CREATE", "USER_CUSTOM_FIELD_UPDATE", "USER_CUSTOM_FIELD_MAKE_REQUIRED", "USER_CUSTOM_FIELD_MAKE_OPTIONAL", "USER_CUSTOM_FIELD_DELETE"];
  let billingTypes = ["CHANGE_BILLING_PLAN", "ASSIGN_BILLING_PLAN", "PAYMENT_SUCCEEDED", "SUBSCRIPTION_CANCELED", "SUBSCRIPTION_EXPIRED", "PAYMENT_FAILED", "UPCOMING_PLAN_ACTIVATED", "PAYMENT_REFUNDED"];

  if (workspaceTypes.includes(type)) {
    // Workspace Activity
    if (["WORKSPACE_DOMAIN_CREATE", "WORKSPACE_DOMAIN_DELETE"].includes(type)) {
      title = activity?.data?.domain;
    } else if (activity?.type === "WORKSPACE_ORDERING") {
      title = "Workspaces Re-ordered";
    } else {
      title = activity?.data?.workspace_name || activity?.data?.workspace_id?.name;
    }
    noTitle = "Workspace Deleted";
  } else if (billingTypes.includes(type)) {
    title = activity?.data?.plan_name || activity?.data?.plan_id?.name;
    subTitles = [activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    if (activity?.type === "ASSIGN_BILLING_PLAN") {
      title = activity?.data?.user_name || activity?.data?.user_id?.name;
      subTitles = [activity?.data?.user_email || activity?.data?.user_id?.email, activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    }
    if (["PAYMENT_SUCCEEDED", "PAYMENT_FAILED", "PAYMENT_REFUNDED"]?.includes(activity?.type)) {
      subTitles = [`Amount: ${formatCurrency(activity?.data?.plan_amount)}`, activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    }
    noTitle = "Plan not found";
  } else if (userTypes.includes(type)) {
    // User Activity
    title = activity?.data?.user_email || activity?.data?.user_id?.email;

    let groupName = activity?.data?.group_name || activity?.data?.group_id?.name;
    let workspaceName = activity?.data?.workspace_name || activity?.data?.workspace_id?.name;
    let combinedName = groupName ? workspaceName + " > " + groupName : workspaceName;
    subTitles = [combinedName];
    noTitle = "User not found";
  } else if (pageTypes.includes(type)) {
    // Page Activity
    if (activity?.data?.page_name || activity?.data?.page_id?.name) {
      title = activity?.data?.page_name || activity?.data?.page_id?.name;
    } else if (activity?.data?.workspace_id?.workspace_type === "JWT_FULL_EMBED") {
      title = <WorkspaceSubscriberInstance />;
    } else {
      noTitle = "Page Deleted";
    }

    if (type === "PAGE_VIEW") {
      subTitles = [activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    } else if (type === "PAGE_PREVIEW") {
      subTitles = [
        activity?.data?.workspace_id ? (
          <>
            {activity?.data?.group_id && !activity?.data?.user_id && "Workspace's group Level"}
            {!activity?.data?.group_id && activity?.data?.user_id && "User's individual Level"}
            {!activity?.data?.group_id && !activity?.data?.user_id && "Workspace's page Level"}
          </>
        ) : activity?.data?.group_id ? (
          "Group Level"
        ) : (
          "Page Level"
        ),
      ];
    } else {
      subTitles = [
        activity?.data?.workspace_name || activity?.data?.workspace_id?.name || (
          <div className="flex items-center space-x-2">
            <GlobeAltIcon className="h-4 w-4" /> Global
          </div>
        ),
      ];
    }
    // noTitle = activity?.type === "PAGE_VIEW" ? "Workspace Deleted" : "Page Deleted";
    // activity?.type === "PAGE_VIEW" ? [activity?.data?.page_name || activity?.data?.page_id?.name ? activity?.data?.page_name || activity?.data?.page_id?.name : activity?.data?.workspace_id?.workspace_type === "JWT_FULL_EMBED" ? <WorkspaceSubscriberInstance /> : "*Page Deleted*"]
  } else if (formTypes.includes(type)) {
    // Form Activity
    title = type === "FORM_RESPONSE_PUSH_TO_DATASET" && !activity?.data?.form_id?._id ? "Form Responses Push To Dataset" : activity?.data?.form_name || activity?.data?.form_id?.name;
    subTitles =
      type === "FORM_RESPONSE_PUSH_TO_DATASET" && !activity?.data?.form_id?._id
        ? []
        : [
            activity?.data?.workspace_name || activity?.data?.workspace_id?.name || (
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-4 w-4" /> Global
              </div>
            ),
          ];
    noTitle = "Form Deleted";
  } else if (type === "ACTIVITY_PUSH_TO_DATASET") {
    title = "Upload Activities To Dataset";
  } else if (groupTypes.includes(type)) {
    // Group Activity
    title = activity?.type === "GROUP_ORDERING" ? activity?.data?.workspace_name || activity?.data?.workspace_id?.name : activity?.data?.group_name || activity?.data?.group_id?.name;
    subTitles = activity?.type === "GROUP_ORDERING" ? [] : [activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    noTitle = activity?.type === "GROUP_ORDERING" ? "Workspace Deleted" : "Group Deleted";
  } else if (credentialTypes.includes(type)) {
    // Credential Activity
    title = activity?.data?.credential_name;
    subTitles = [activity?.data?.user_name || activity?.data?.user_id?.name, activity?.data?.user_email || activity?.data?.user_id?.email, activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    noTitle = "Credential Deleted";
  } else if (ssoTypes.includes(type)) {
    // SSO Activity
    title = activity?.data?.sso_name || activity?.data?.sso_id?.provider_name;
    subTitles = [activity?.data?.workspace_name || activity?.data?.workspace_id?.name];
    noTitle = "SSO Deleted";
  } else if (customFieldTypes.includes(type)) {
    // Custom Field Activity
    title = activity?.data?.custom_field_name || activity?.data?.custom_field_id?.label;
    noTitle = "Custom Field Deleted";
  } else if (type === "LOGIN") {
    title = "Authentication";
  } else if (type === "SETTINGS_UPDATE") {
    title = "Settings Update";
  } else if (type === "SMTP_UPDATE") {
    title = "SMTP Update";
  } else if (type === "USER_PUSH_TO_DATASET") {
    title = "Upload Users To Dataset";
  } else if (type === "USER_PULL_FROM_DATASET") {
    title = "Pull Users From Dataset";
  } else if (type === "USER_PULL_FROM_BULK_UPLOAD") {
    title = "Upload Bulk Upload";
  } else if (type === "ACTIVITY_PUSH_TO_DATASET") {
    title = "Upload Activities To Dataset";
  } else if (type === "EMAIL_TEMPLATE_UPDATE") {
    title = activity?.data?.email_template_name || activity?.data?.email_template_id?.subject;
    noTitle = "Email template Deleted";
  } else if (type === "WORKSPACE_IMPORT_DOMO_USER") {
    title = activity?.data?.workspace_name || activity?.data?.workspace_id?.name;
    noTitle = "Workspace Deleted";
  }

  return (
    <TableRow keyId={activity?._id}>
      {/* COLUMN: Name */}
      <td className="w-1/3 whitespace-pre-wrap break-all px-3 py-2 pl-4 text-gray-500 sm:table-cell sm:pl-6 sm:text-xs md:w-1/5">
        <BasicActivityContent
          title={primaryName}
          subTitles={[secondaryName]}
        />
      </td>
      {/* COLUMN: Activity Type */}
      <td className="hidden w-1/5 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:text-xs lg:table-cell">
        <BasicActivityContent subTitles={[`${activity?.type} ${activity?.type === "LOGIN" && activity?.data?.login_method === "SSO_AUTH" ? "(SSO)" : ""}`]} />
      </td>
      {/* COLUMN: Description */}
      <td className="break-word hidden w-1/3 whitespace-pre-wrap px-3 py-2 text-gray-500 sm:table-cell sm:text-xs md:w-1/5">
        <BasicActivityContent
          title={title}
          subTitles={subTitles}
          noTitle={noTitle}
        />
      </td>
      {/* COLUMN: Date */}
      <td className="w-1/4 whitespace-pre-wrap break-all px-3 py-2 text-xs text-gray-500 sm:table-cell lg:w-1/5">
        <BasicActivityContent
          title={
            <>
              <div className="hidden text-gray-900 2xl:block">{formatDate(activity?.created_at, "LLLL")}</div>
              <div className="flex flex-col text-gray-900 2xl:hidden">
                {formatDate(activity?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(activity?.created_at, "h:mm:ssA")}</span>
              </div>
            </>
          }
        />
      </td>
      {/* COLUMN: Location/IP Address */}
      <td className="hidden w-1/3 break-all px-3 py-2 text-xs text-gray-500 lg:table-cell">
        <BasicActivityContent
          title={login_portal}
          subTitles={[<>{me?.default_user && <>{data?.ip_address ? <span className="text-gray-500">{data?.ip_address}</span> : <span className="text-gray-300">{login_portal === "SYSTEM" ? "N/A" : "Not recorded"}</span>}</>} </>]}
        />
      </td>
    </TableRow>
  );
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {})(ActivityEntry);
