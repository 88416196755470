import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { managePageData, removePage, setPageData, updateDomoPage } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import PageEntry from "src/components/Pages/PageEntry";
import PageListItem from "src/components/Pages/PageListItem";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserProfile from "src/components/Users/UserProfile";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import { PageListContext } from "src/context/PageListContext";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import useFetch from "src/hooks/useFetch";
import { validate as uuidValidate } from "uuid";
import Image from "src/components/Shared/Image";
import DomoPage from "src/components/Pages/PageTypes/DomoPage/DomoPage";
import HtmlPage from "src/components/Pages/PageTypes/HtmlPage";
import LinkPage from "src/components/Pages/PageTypes/LinkPage";
import MetabasePage from "./PageTypes/MetabasePage/MetabasePage";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import QuickSightPage from "./PageTypes/QuickSightPage/QuickSightPage";
import FormPage from "./PageTypes/FormPage";
import { useNavigate } from "react-router";
import InformationAlert from "../Shared/Alerts/InformationAlert";
import { credentialTypes, newPagaJsonArr, pageCreatePayload, pageTypes } from "src/helpers/pageHelper";
import SlideOver from "../Shared/SlideOver";
import PageEdit from "./PageEdit";
import PagePreview from "./PageModals/PagePreview";

const PageContainer = ({ isGlobal = false, workspaceId = "", ...props }) => {
  const [createModal, setCreateModal] = useState(false);
  const [userAssignModal, setUserAssignModal] = useState(false);
  const [groupAssignModal, setGroupAssignModal] = useState(false);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [successLoaderStart, setSuccessLoaderStart] = useState(false);
  const [startIndex, setStartIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [isDeleteDisable, serIsDeleteDisable] = useState(false);
  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newPage, setNewPage] = useState(newPagaJsonArr(workspaceId, isGlobal));
  const [isDefaultBiPlatform, setIsDefaultBiPlatform] = useState("");

  const [pageCredentialOptions, setPageCredentialOptions] = useState([]);
  const [editPageCredentialOptions, setEditPageCredentialOptions] = useState([]);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  // Users and groups
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const [domoPageId, setDomoPageId] = useState([]);
  const [domoUserIds, setDomoUserIds] = useState([]);
  const [domoGroupIds, setDomoGroupIds] = useState([]);
  const [domoPageIdForRefresh, setDomoPageIdForRefresh] = useState([]);
  const [pageSelectedId, setPageSelectedId] = useState([]);

  // Page preview
  const [prevModalOpen, setPrevModalOpen] = useState(false);
  const [prevPageDetails, setPrevPageDetails] = useState(null);

  // Platform embed
  const [platformEmbeds, setPlatformEmbeds] = useState([]);

  // No credential modal
  const [credentialAlertData, setCredentialAlertData] = useState(null);

  //Page Edit Slider
  const [pageSlider, setPageSlider] = useState(false);

  //Form List
  const [formList, setFormList] = useState([]);

  const {
    keyword = "",
    setKeyword = () => {},
    meta = null,
    setMeta = () => {},
    limit = 10,
    setLimit = () => {},
    offset = 0,
    setOffset = () => {},
    pagesLoaded = false,
    setPagesLoaded = () => {},
    pageType = null,
    setPageType = () => {},
    pages = [],
    allPages = [],
    setPages = () => {},
    resetPagination = () => {},
    refreshPages = () => {},
  } = useContext(PageListContext);

  useEffect(() => {
    if (!workspaceId) {
      resetPagination();
      setKeyword("");
      setMeta(null);
      setLimit(10);
      setOffset(0);
      setPagesLoaded(false);
      setPageType(null);
      setPages([]);
      refreshPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobal, workspaceId]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { includes: ["authorized_workspaces", "forms"] } });

  const {
    response: { data: forms },
  } = useFetch("/forms", { method: "get", query: { is_global: true } });

  const loadUserGroupData = useCallback(async () => {
    const params = { workspace_id: props?.workspaceDetails?._id };
    const res = await apiRequest("post", `/workspaces/:workspace_id/user-groups`, {
      body: {
        authToken: props?.workspaceDetails?.auth_token,
        workspace_id: props?.workspaceDetails?._id,
      },
      params,
    });

    if (res.data.status === 200) {
      setUsers(res.data.data.users || []);
      setGroups(res.data.data.groups || []);
    }
  }, [props?.workspaceDetails]);

  const allCredentials = useCallback(async () => {
    const res = await apiRequest("get", `/credentials/all`);

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, []);

  const workspaceCredentials = useCallback(async () => {
    const params = { workspace_id: workspaceId };
    const res = await apiRequest("get", `/workspaces/:workspace_id/credentials`, { params });

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId || props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
      if (props?.user?.type === "admin") {
        allCredentials();
      } else if (workspaceId) {
        workspaceCredentials();
      }
    }
  }, [allCredentials, workspaceCredentials, workspaceId, props?.user, props?.workspaceDetails]);

  useEffect(() => {
    if (props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
      loadUserGroupData();
    }
  }, [loadUserGroupData, props?.workspaceDetails]);

  const createNewPage = async () => {
    try {
      setSuccessLoaderStart(true);
      const page = pageCreatePayload(newPage, nestedAllPages, selectedWorkspace, props?.workspaceDetails, props?.user);
      const { message, data } = await props.managePageData({
        pages: [page],
        workspaceId,
        pageId: newPage._id || null,
      });
      refreshPages();
      if (!pageSlider) {
        setCreateModal(false);
        setAdvancedFeatures(false);
        setNewPage(newPagaJsonArr(workspaceId, isGlobal));
      } else {
        setPrevPageDetails({ data, body: { user_id: props?.user?._id, page_id: data?._id, workspace_id: workspaceId } });
      }
      setTimeout(() => {
        setSuccessLoaderStart(false);
        toast.success(message);
      }, 200);
    } catch (error) {
      setTimeout(() => {
        setSuccessLoaderStart(false);
        toast.error(error.message);
      }, 300);
    }
  };

  const deletePage = async () => {
    if (deleteId && !uuidValidate(deleteId)) {
      try {
        setCreateModal(false);
        serIsDeleteDisable(true);
        await props.removePage({
          workspaceId,
          deleteId,
        });
        setDeleteId(null);
        if (pages.length === 1 && offset !== 0) {
          setOffset(offset - 1);
        } else {
          refreshPages();
        }
      } catch (error) {
        // console.dir("ERROR:", error);
      } finally {
        serIsDeleteDisable(false);
      }
    }
  };

  const editPage = async (page) => {
    if (page.workspace_id.length > 0 || page.is_global) {
      let workspace_id = [];
      for (const workspaceid of page.workspace_id) {
        workspace_id.push(workspaceid._id);
      }
      setSelectedWorkspace(workspace_id);
      setNewPage({ ...page, workspace_id: "", is_global: true });
    } else {
      setSelectedWorkspace([]);
      setNewPage({ ...page, workspace_id: page.workspace_id?._id, is_global: false });
    }
    setPageSlider(true);
    setPrevPageDetails({ page, body: { user_id: props?.user?._id, page_id: page?._id, workspace_id: workspaceId } });
  };

  const addPage = async (pageType) => {
    setSelectedWorkspace([]);
    setNewPage({ ...newPagaJsonArr(workspaceId, isGlobal), credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value || null });
    setCreateModal(true);
  };

  const pagePrev = async (page) => {
    setPrevPageDetails({ page, body: { user_id: props?.user?._id, page_id: page?._id, workspace_id: workspaceId } });
    setPrevModalOpen(true);
  };

  useEffect(() => {
    let creds = [];
    // eslint-disable-next-line array-callback-return
    editPageCredentialOptions?.map((credentialOption) => {
      let insert = false;
      const { _id, workspace_id, name, code, is_global } = credentialOption;
      if (workspace_id && newPage?.workspace_id === workspace_id) {
        insert = true;
      }
      if (props.user?.type === "admin" && is_global) {
        insert = true;
      }
      if (insert === true && creds.findIndex((cr) => cr.value === _id) === -1 && newPage?.page_type.toLowerCase() === code) {
        const credentialComponent = () => credentialTypes(props?.user?._id)[credentialOption.default ? "DEFAULT" : "NORMAL"](credentialOption);
        creds.push({ value: _id, key: name, code: code, default: credentialOption.default, object: credentialOption, Component: credentialComponent });
      }
    });
    setPageCredentialOptions(creds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPageCredentialOptions, newPage?.workspace_id, newPage?.page_type, props.user]);

  useEffect(() => {
    if (!newPage?._id) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value || null,
      }));
    }
  }, [newPage.workspace_id, newPage?._id, pageCredentialOptions]);

  useEffect(() => {
    setStartIndex(pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id));
  }, [pageCredentialOptions, newPage]);

  useEffect(() => {
    const authorizedWorkspaces = workspaces?.find((wrk) => wrk?._id?.toString() === newPage?.workspace_id?.toString())?.authorized_workspaces || [];
    setPlatformEmbeds(authorizedWorkspaces?.filter((item) => item?.provider_code === newPage.page_type?.toLowerCase()));
  }, [workspaces, newPage.workspace_id, newPage.page_type]);

  useEffect(() => {
    if (isGlobal) {
      setFormList(forms);
    } else {
      setFormList(workspaces?.find((wrk) => wrk?._id?.toString() === newPage?.workspace_id)?.forms || []);
    }
  }, [workspaces, newPage?.workspace_id, isGlobal, forms]);

  const activeUserAssignModal = async (pageId, userIds = [], ownerId) => {
    setDomoPageId(pageId);
    let userWithSelected = [];
    for (const user of users) {
      userWithSelected.push({ ...user, checked: userIds.findIndex((usr) => usr === user.id) === -1 ? false : true, owner: user.id === ownerId ? true : false });
    }
    setDomoUserIds(userWithSelected);
    setUserAssignModal(true);
  };

  const activeGroupAssignModal = async (pageId, groupIds = []) => {
    setDomoPageId(pageId);
    let groupWithSelected = [];
    for (const group of groups) {
      groupWithSelected.push({ ...group, checked: groupIds.findIndex((grp) => grp === group.id) === -1 ? false : true });
    }
    setDomoGroupIds(groupWithSelected);
    setGroupAssignModal(true);
  };

  const handleUserGroupAssign = async (type, importStatus = undefined) => {
    try {
      let ids;
      if (type === "user") {
        let filterArr = domoUserIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      } else {
        let filterArr = domoGroupIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      }

      if (typeof importStatus !== "boolean" && ids.length && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (users.filter((user) => ids.includes(user.id || user._id) && !user.portalUser).length) {
          setUserAssignModal(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessLoaderStart(true);

      for (const pageId of domoPageId) {
        await updateDomoPage({
          page_id: pageId,
          workspace_id: workspaceId,
          userIds: type === "user" ? ids : undefined,
          groupIds: type === "group" ? ids : undefined,
          auth_token: props?.workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
        });
      }
      setDomoPageIdForRefresh(domoPageId);
      setSuccessLoaderStart(false);
      setUserAssignModal(false);
      setGroupAssignModal(false);
      setGroupImportUserModalOpen(false);
      loadUserGroupData();
    } catch (error) {
      setSuccessLoaderStart(false);
      toast.error(error.message);
    }
  };

  const pageSelect = async (e, pageId) => {
    if (e.target.checked) {
      setPageSelectedId([...pageSelectedId, pageId]);
    } else {
      setPageSelectedId(pageSelectedId.filter((p) => p !== pageId));
    }
  };

  const reorderNestedPages = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setNewPage((page) => ({
      ...page,
      nested_pages: result,
    }));
  };

  const onPageTypeChange = (e) => {
    setCredentialAlertData(e);
  };

  const redirectToCredential = () => {
    if (newPage?.is_global) {
      navigate(`/site-settings?tab=Auth+Credentials&opencredentialmodal=true&credentialtype=${credentialAlertData?.key?.toLowerCase()}`);
    } else if (newPage?.workspace_id) {
      navigate(`/workspaces/${newPage?.workspace_id}?tab=Settings&opencredentialmodal=true&credentialtype=${credentialAlertData?.key?.toLowerCase()}`);
    } else if (!newPage?.is_global && !newPage?.workspace_id) {
      toast.error("Please select the workspace for redirection");
    }
  };

  const redirectToAddForm = () => {
    const workspaceIds = Array.isArray(selectedWorkspace) ? selectedWorkspace : [selectedWorkspace];
    if (newPage?.is_global) {
      navigate(`/forms/add?workspace_id=${encodeURIComponent(JSON.stringify(workspaceIds))}&is_global=true&pathname=/forms?tab=Global+forms`);
    } else if (newPage?.workspace_id) {
      navigate(`/forms/add?workspace_id=${newPage?.workspace_id}&is_global=false&pathname=/${window.location.pathname.includes("/workspaces") ? "workspaces" : ""}?tab=All+forms`);
    } else if (!newPage?.is_global && !newPage?.workspace_id) {
      toast.error("Please select the workspace for redirection");
    }
  };

  const pageTypeSetFun = useMemo(() => {
    let types = [];
    // Get unique codes and sort alphabetically
    const uniqueCodes = Array.from(new Set(editPageCredentialOptions.map((item) => item.code))).sort();

    if (newPage._id) {
      types.push({ key: newPage.page_type, value: newPage.page_type, Component: () => pageTypes[newPage.page_type] });
    } else {
      types.push({ key: "DOMO", value: "DOMO", Component: () => pageTypes["DOMO"], notClickable: editPageCredentialOptions.find((editPageCredentialOption) => editPageCredentialOption?.code === "domo") ? false : true });

      // Don't render other BI platforms unless explicitly allowed.
      if (editPageCredentialOptions.find((editPageCredentialOption) => editPageCredentialOption?.code === "metabase")) {
        types.push({ key: "METABASE", value: "METABASE", Component: () => pageTypes["METABASE"], notClickable: editPageCredentialOptions.find((editPageCredentialOption) => editPageCredentialOption?.code === "metabase") ? false : true });
      }
      if (editPageCredentialOptions.find((editPageCredentialOption) => editPageCredentialOption?.code === "quicksight")) {
        types.push({ key: "QUICKSIGHT", value: "QUICKSIGHT", Component: () => pageTypes["QUICKSIGHT"], notClickable: editPageCredentialOptions.find((editPageCredentialOption) => editPageCredentialOption?.code === "quicksight") ? false : true });
      }

      // In the case that multiple BI platforms, but only one credential type
      if (uniqueCodes.length === 1) {
        setIsDefaultBiPlatform(uniqueCodes[0].toUpperCase());
      }

      types.push({ key: "FORM", value: "FORM", Component: () => pageTypes["FORM"] });
      types.push({ key: "CUSTOM_HTML", value: "CUSTOM_HTML", Component: () => pageTypes["CUSTOM_HTML"] });
      types.push({ key: "EXTERNAL_LINK", value: "EXTERNAL_LINK", Component: () => pageTypes["EXTERNAL_LINK"] });
    }
    return types;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage.page_type, newPage._id, editPageCredentialOptions]);

  const nestedAllPages = allPages
    .filter((page) => page._id !== newPage._id && (!Array.isArray(page.workspace_id) ? page.workspace_id?._id === newPage.workspace_id : page.workspace_id.map((item) => item._id).some((item) => selectedWorkspace.includes(item))))
    .map((page) => ({ _id: page._id, name: page.name }));

  const pageForm = () => {
    return (
      <div className="space-y-8 whitespace-nowrap text-sm text-gray-500">
        <div className="mb-8">
          <div className="mb-5 space-y-2">
            {(newPage?.workspace_id?.page?.is_global_template || isGlobal) && (
              <MultiSelectObject
                inline={true}
                defaultOptions={
                  workspacesLoaded &&
                  workspaces
                    .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                    .map((wrk) => {
                      return { key: wrk._id, value: wrk.name, selected: selectedWorkspace.includes(wrk._id), object: wrk };
                    })
                }
                searchableFields={["name"]}
                title="Assign workspaces"
                onChange={handleSelectedWorkspaces}
              />
            )}
            {!newPage?._id && !newPage?.workspace_id?.page?.is_global_template && !isGlobal && !workspaceId && !props.workspaceDetails?._id && (
              <>
                <div className="grid gap-y-5">
                  <SelectMenu
                    label="Select workspace"
                    inline={true}
                    startIndex={-1}
                    options={workspaces
                      .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                      .map((wrk) => {
                        return {
                          key: wrk.name,
                          value: wrk._id,
                          Component: () => (
                            <div className="flex items-center gap-x-3">
                              <div
                                style={!wrk?.square_logo && wrk.image_logo ? { background: wrk.top_bar_color } : {}}
                                className="h-5 w-5 overflow-hidden rounded bg-white/10">
                                <Image
                                  image={wrk.square_logo || wrk.image_favicon || wrk.image_logo}
                                  alt={"Workspace"}
                                />
                              </div>
                              <p>{wrk.name}</p>
                            </div>
                          ),
                        };
                      })}
                    setOption={(option) => {
                      setNewPage({ ...newPage, workspace_id: option.value, credential_id: null, form_id: null });
                    }}
                  />
                </div>
              </>
            )}
            <div className="flex w-full flex-col gap-x-3 gap-y-3">
              <div className="flex-none sm:w-1/3">
                {isDefaultBiPlatform ? (
                  <div className="mb-2 ml-1 flex h-full gap-x-6">
                    {pageTypeSetFun && pageTypeSetFun?.find((pageType) => pageType.key === isDefaultBiPlatform)?.Component()}
                    <Button
                      onClick={() => setIsDefaultBiPlatform("")}
                      version="default"
                      className="!h-auto px-2 py-1 text-sm text-gray-300 underline transition-all hover:text-gray-400">
                      Edit default
                    </Button>
                  </div>
                ) : (
                  <SelectMenu
                    disabled={pageTypeSetFun.length === 1 || pageTypeSetFun.length === 0}
                    isSelected={pageTypeSetFun.length === 1}
                    label="Page type"
                    inline={true}
                    dropdownClass={"!min-w-0 w-full"}
                    startIndex={pageTypeSetFun?.findIndex((page) => page?.key === newPage?.page_type)}
                    options={pageTypeSetFun}
                    setOption={(e) => setNewPage({ ...newPage, page_type: e.value, credential_id: null, dashboard_id: null, embed_id: "", embed_type: "EMBED_PAGE", bordered: false, titled: false, theme: "", platform_embed: null, form_id: null })}
                    onHandleSelect={onPageTypeChange}
                  />
                )}
              </div>
              <div className="flex-grow">
                <Input
                  type="text"
                  name="page-name"
                  label="Name"
                  inline={true}
                  value={newPage.name}
                  onChange={(e) => setNewPage({ ...newPage, name: e.target.value })}
                />
              </div>
            </div>
          </div>
          <hr className="mb-6" />
          <div className="relative">
            {newPage.page_type === "FORM" && (
              <FormPage
                newPage={newPage}
                setNewPage={setNewPage}
                workspaces={workspaces}
                isGlobal={newPage?.workspace_id?.page?.is_global_template || isGlobal}
                forms={forms}
              />
            )}

            {newPage.page_type === "CUSTOM_HTML" && (
              <HtmlPage
                newPage={newPage}
                setNewPage={setNewPage}
              />
            )}

            {newPage.page_type === "EXTERNAL_LINK" && (
              <LinkPage
                newPage={newPage}
                setNewPage={setNewPage}
              />
            )}

            {newPage.page_type === "DOMO" && (
              <DomoPage
                pageTypes={pageTypes}
                newPage={newPage}
                setNewPage={setNewPage}
                startIndex={startIndex}
                pageCredentialOptions={pageCredentialOptions}
                advancedFeatures={advancedFeatures}
                setAdvancedFeatures={setAdvancedFeatures}
                nestedAllPages={nestedAllPages}
                reorderNestedPages={reorderNestedPages}
                workspaceDetails={props?.workspaceDetails}
                user={props?.user}
                workspaceId={workspaceId}
                platformEmbeds={platformEmbeds}
              />
            )}

            {newPage.page_type === "QUICKSIGHT" && (
              <QuickSightPage
                newPage={newPage}
                setNewPage={setNewPage}
                startIndex={startIndex}
                pageCredentialOptions={pageCredentialOptions}
                platformEmbeds={platformEmbeds}
              />
            )}

            {newPage.page_type === "METABASE" && (
              <MetabasePage
                newPage={newPage}
                setNewPage={setNewPage}
                startIndex={startIndex}
                pageCredentialOptions={pageCredentialOptions}
                platformEmbeds={platformEmbeds}
              />
            )}
          </div>
          {credentialAlertData?.key && (
            <div className="relative">
              <InformationAlert
                content={
                  <div className="flex flex-wrap space-x-1">
                    <span>
                      There is no credential for {credentialAlertData?.key} page, to create {credentialAlertData?.key} credential please click
                    </span>
                    <Button
                      version="default"
                      className="!inline-flex !h-auto text-highlightColor underline"
                      onClick={redirectToCredential}>
                      here
                    </Button>
                  </div>
                }
                type="warning"
              />
            </div>
          )}

          {newPage.page_type === "FORM" && formList?.length === 0 && (
            <div className="relative">
              <InformationAlert
                content={
                  <div className="flex flex-wrap space-x-1">
                    <span>There is no Form , to create a Form please click</span>
                    <Button
                      version="default"
                      className="!inline-flex !h-auto text-highlightColor underline"
                      onClick={redirectToAddForm}>
                      here
                    </Button>
                  </div>
                }
                type="warning"
              />
            </div>
          )}
        </div>

        {pageSlider && (
          <div className="flex justify-end gap-4">
            <Button
              version="secondary"
              disabled={successLoaderStart}
              onClick={() => {
                if (!deleteId) {
                  setPageSlider(false);
                }
              }}>
              Cancel
            </Button>
            <Button
              loading={successLoaderStart}
              disabled={successLoaderStart}
              onClick={createNewPage}>
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              resetPagination();
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          (workspaceId && props.workspaceDetails?._id && props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"])) || (props.user?.type === "admin" && !workspaceId) ? (
            <Button
              version="secondary"
              onClick={() => addPage("DOMO")}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              {width > 640 ? "Add page" : "Page"}
            </Button>
          ) : (
            workspaceId &&
            props.workspaceDetails?._id &&
            props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" &&
            authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"]) && (
              <div className="relative z-10 flex items-center justify-between">
                <div className="flex h-10 gap-x-2">
                  <Button
                    version="secondary"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeUserAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-[3px]" />
                    Add users
                  </Button>
                  <Button
                    version="secondary"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeGroupAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-[3px]" />
                    Add groups
                  </Button>
                </div>
              </div>
            )
          )
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
              {pageType === "IFRAME_EMBED" && "Type"}
              {pageType === "JWT_FULL_EMBED" && "Users"}
            </th>
            {pageType && pageType === "JWT_FULL_EMBED" && (
              <th
                scope="col"
                className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
                Groups
              </th>
            )}
            {pageType && pageType === "IFRAME_EMBED" && (
              <th
                scope="col"
                className="overflow-hidden  rounded-tr-lg px-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
            )}
          </TRHeader>
        }
        colSpan="5"
        loaded={pagesLoaded}
        dataExists={pagesLoaded && pages?.length > 0}>
        {pages.map((page, i) => {
          return (
            <PageListItem
              key={page._id || page.id}
              page={page}
              pages={pages}
              i={i}
              workspaceId={workspaceId}>
              <PageEntry
                workspaceId={page.workspace_id?._id || page.workspace_id || workspaceId}
                key={page._id || page.id}
                page={page}
                setDeleteId={setDeleteId}
                editPage={editPage}
                pagePrev={pagePrev}
                pageType={pageType}
                is_global={isGlobal}
                users={users}
                groups={groups}
                workspaceDetails={props?.workspaceDetails}
                depthLevel={0}
                activeUserAssignModal={activeUserAssignModal}
                activeGroupAssignModal={activeGroupAssignModal}
                domoPageIdForRefresh={domoPageIdForRefresh}
                setDomoPageIdForRefresh={setDomoPageIdForRefresh}
                pageSelect={pageSelect}
              />
            </PageListItem>
          );
        })}
      </Table>
      {pageType === "IFRAME_EMBED" && (
        <PaginationFooter
          itemName="Page"
          limit={limit}
          offset={offset}
          count={meta?.count}
          onChange={handlePaginationChange}
        />
      )}
      {/* { Page Delete} */}
      <Modal
        title="Page"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => {
          setDeleteId(null);
        }}
        onSuccess={deletePage}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="space-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {Array.isArray(pages) && pages?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>

      {/* { Page Create Modal} */}
      <Modal
        title="Page"
        secondaryTitle={!newPage?._id ? "Create" : "Edit"}
        isOverflow={false}
        isOpen={createModal}
        onCancel={() => {
          if (!deleteId) {
            setCreateModal(false);
          }
        }}
        onSuccess={createNewPage}
        defaultStyles={{
          overFlowYVisible: true,
        }}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        size={newPage.page_type === "LANDING_PAGE" ? "xl" : "md"}>
        {pageForm()}
      </Modal>

      {/* { Page Update/View Slider} */}
      <SlideOver
        isOpen={pageSlider}
        handleClose={() => {
          setPageSlider(false);
          setPrevPageDetails(null);
        }}
        title="Edit page"
        description="View, update, and modify page details and information below."
        maxWidth="xl:max-w-[1300px] lg:max-w-[1100px]">
        <>
          <PageEdit
            pageForm={pageForm}
            isSliderOpen={pageSlider}
            pageDetails={prevPageDetails}
          />
        </>
      </SlideOver>

      {/* { Page Preview} */}
      <Modal
        title="Page"
        secondaryTitle="Preview"
        isOpen={prevModalOpen}
        onCancel={() => {
          setPrevModalOpen(false);
          setPrevPageDetails(null);
        }}
        size="xxxxxl"
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}>
        <div className={!localStorage.getItem("impersonatedUserId") ? "h-[calc(100vh-10rem)]" : props?.workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "h-[calc(100vh-4rem)] lg:h-[calc(100vh-120px)]" : "h-[calc(100vh-4rem)]"}>
          <PagePreview
            isOpen={prevModalOpen}
            pageDetails={prevPageDetails}
          />
        </div>
      </Modal>

      {/* User Assign/Unassign to page Modal */}
      <Modal
        title="Users"
        size="sm"
        isOpen={userAssignModal}
        onCancel={() => setUserAssignModal(false)}
        onSuccess={() => handleUserGroupAssign("user")}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="w-full divide-y divide-gray-200">
          {domoUserIds.map((user, i) => (
            <div
              key={user.id || user._id}
              className="flex w-full items-center space-x-3 py-2">
              <Checkbox
                isChecked={user.checked || false}
                disabled={user.owner || false}
                forceCheck={user.owner || false}
                onChange={(e) => {
                  setDomoUserIds(
                    domoUserIds.map((usr, index) => {
                      if (i === index) {
                        return { ...usr, checked: e.target.checked };
                      } else {
                        return usr;
                      }
                    }),
                  );
                }}
              />
              <div className="relative flex items-start gap-x-2">
                <div className="flex flex-col gap-x-2">
                  <div>
                    <div className="relative flex items-center gap-2">
                      <div>{user.name}</div>
                      {user.owner && (
                        <div
                          className="rounded bg-purple-100 px-1 py-1 text-xs text-purple-600"
                          data-tooltip-id={`${user.email}-page_owner`}>
                          Owner
                        </div>
                      )}
                      {typeof user?.portalUser === "boolean" && !user?.portalUser && (
                        <div
                          data-tooltip-id={`${user.email}-not_portal_user_search`}
                          className="flex bg-amber-50 py-1 font-light text-amber-400">
                          <ExclamationTriangleIcon className="h-5 w-5 stroke-[2px]" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-sm text-gray-300">{user.email}</div>
                </div>

                <ReactTooltip
                  id={`${user.email}-not_portal_user_search`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
                  <div className="font-normal">
                    <div>This user was created by a Domo admin, but has not yet been give an account to access the application.</div>
                    <div>If you add this user a welcome email will be issued.</div>
                  </div>
                </ReactTooltip>
                <ReactTooltip
                  id={`${user.email}-page_owner`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
                  <div className="font-normal">
                    <div>This is the page owner.</div>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <WorkspaceImportUserModal
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        selectedUsers={domoUserIds.filter((dt) => dt.checked).map((item) => item.id)}
        workspaceUsers={users}
        onSuccess={(users, status) => handleUserGroupAssign("user", status)}
        workspaceDetailsData={props?.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successLoaderStart}
      />

      {/* Group Assign/Unassign to page Modal */}
      <Modal
        title="Groups"
        size={"sm"}
        isOpen={groupAssignModal}
        onCancel={() => setGroupAssignModal(false)}
        onSuccess={() => handleUserGroupAssign("group")}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="w-full divide-y divide-gray-200">
          {domoGroupIds.map((group, i) => (
            <div
              key={group._id || group.id}
              className="flex w-full items-center space-x-3 py-2">
              <Checkbox
                isChecked={group.checked || false}
                onChange={(e) => {
                  setDomoGroupIds(
                    domoGroupIds.map((grp, index) => {
                      if (i === index) {
                        return { ...grp, checked: e.target.checked };
                      } else {
                        return grp;
                      }
                    }),
                  );
                }}
              />
              <UserProfile
                user={group}
                className={"!h-10 !w-10 overflow-hidden rounded-full"}
              />
              <div>{group.name}</div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageContainer);
