import { CalendarIcon } from "@heroicons/react/24/outline";
import { getStatusStyles } from "src/helpers/billingHelper";
import { classNames } from "src/helpers/classNames";
import { formatCurrency } from "src/helpers";
import { connect } from "react-redux";
import moment from "moment";
import Button from "src/components/Shared/Buttons/Button";
import AssignPlanModal from "./AssignPlanModal";
import { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import useMounted from "src/hooks/useMounted";

const PlanDetails = ({ planDetails, workspaceDetails, loadPlanDetails }) => {
  const [plans, setPlans] = useState([]);
  const [assignModal, setAssignModal] = useState(false);

  const isMounted = useMounted();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await apiRequest("get", "/plans");
        if (isMounted.current) {
          setPlans(response?.data?.data?.filter((item) => item?.pricing?.type !== "no_charge"));
        }
      } catch (err) {}
    };
    fetchPlans();
  }, [isMounted]);

  return (
    <>
      <div className="relative flex w-full gap-4 pb-2">
        <PlanCard
          plan={planDetails}
          isUpcoming={false}
          setAssignModal={setAssignModal}
          status={planDetails?.subscription_status}
        />
        {planDetails?.upcoming_plan && (
          <PlanCard
            plan={planDetails?.upcoming_plan}
            status="upcoming"
            isUpcoming
          />
        )}
      </div>
      <AssignPlanModal
        isOpen={assignModal}
        setIsOpen={setAssignModal}
        setRefresh={loadPlanDetails}
        isActivePlan={planDetails?.subscription_status === "active"}
        defaultPlan={planDetails?.plan_details?._id || ""}
        title={`${workspaceDetails?.name}`}
        plans={plans}
        secondaryTitle={"Billing / Plan Change"}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(PlanDetails);

const PlanCard = ({ plan, status, isUpcoming, setAssignModal }) => {
  if (!plan) return null;

  const { className, label } = getStatusStyles(status);
  const selectedRate = plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === plan?.subscription?.recurring_type);

  const pricePerMonth = formatCurrency(((selectedRate?.amount || 0) - (selectedRate?.discount_amount || 0)) / 12);
  const totalPrice = selectedRate ? selectedRate.amount : 0;
  const discountedPrice = totalPrice - (selectedRate?.discount_amount || 0);

  return (
    <div className="grid gap-y-4">
      <div className="max-w-[500px] rounded-md border p-4 pb-1">
        <div className="flex w-full items-center gap-2 text-2xl font-semibold">
          {plan?.plan_details?.name}
          <div className={classNames("flex h-8 items-center justify-center gap-2 rounded-lg border bg-white px-2 text-base", className)}>{label}</div>
        </div>
        <div className="py-1 pl-0.5 text-sm opacity-75">{plan?.plan_details?.description || "No description provided."}</div>

        <div className="relative mt-2 flex w-full flex-col gap-4 rounded-md border p-3">
          {selectedRate?.discount_type !== "none" && <div className="absolute right-0 top-0 rounded-bl-lg bg-slate-200 p-1.5 text-sm">{selectedRate?.discount_percentage}% OFF</div>}
          <div className="text-lg font-medium">{!["active", "canceled"].includes(plan?.subscription_status) ? "Not Billed Yet" : `Billed ${plan?.subscription?.recurring_type}ly`}</div>
          <div className="flex items-center justify-between">
            <div className="text-lg text-yellow-600">
              {pricePerMonth}
              <span className="pl-0.5 text-base">/month</span>
            </div>
            <div>
              Total: {formatCurrency(discountedPrice)}
              {selectedRate?.discount_amount > 0 && <span className="ml-1 text-slate-500 line-through">{formatCurrency(totalPrice)}</span>}
            </div>
          </div>
        </div>

        {!isUpcoming && plan?.subscription_status !== "pending" && (
          <div className="mt-4 flex flex-col gap-4 border-t pb-3 pt-4">
            <div className="flex items-center justify-between gap-4 text-lg">
              <h3 className="font-semibold">{["canceled", "expired", "on_trial", "on_trial_expired"].includes(plan?.subscription_status) ? (moment(plan?.end_date).isSameOrAfter(moment(), "day") ? "Expiring on:" : "Expired on:") : "Next Billing Date"}</h3>
              <div className="flex items-center justify-center gap-1 font-medium text-gray-600">
                <CalendarIcon className="h-6 w-6" />
                {moment(plan?.end_date).format("MMM DD, YYYY")}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mb-2 flex items-center justify-between font-semibold">
        {!isUpcoming && (
          <Button
            version="secondary"
            onClick={() => setAssignModal(true)}>
            <div className="flex gap-x-3">Change Plan</div>
          </Button>
        )}
      </div>
    </div>
  );
};
